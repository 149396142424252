export function allRequiredFull(payload) {
  return !Object.values(payload).some(field => field === "");
}

export function isEmailValid(email) {
  if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return false;
  }
  return true;
}

export function passwordMatch(password, confirm) {
  return password === confirm;
}
