import styled from "styled-components";
import { colors } from "../../styles/constants";

export const TabNavigator = styled.nav`
  max-width: 560px;
  margin: auto;
  display: flex;

  a {
    border-bottom: 1px solid ${colors.primary};
    padding: 1.2rem 1rem;
  }

  a.active {
    border-bottom: 3px solid ${colors.primary};
    font-weight: 600;
  }
`
