import React from "react";
import Modal from "../../components/Modal/Modal";
import { LinkButton } from "../../styles/buttons";
import { AddContainer } from "./indicationsStyles";
import { siteUrl } from "../../providers/config";

const LinkModal = props => {

  const url = `${siteUrl}cadastro/${props.link}`;

  const toClipboard = () => {
    navigator.clipboard.writeText(url);
  }

  return (
    <Modal toggle={props.toggle} isOpen={props.show}>
      <AddContainer>
        <h3>Compartilhe o link abaixo</h3>
        <div className="link-container">
          <p className="link">{url}</p>
          <LinkButton onClick={toClipboard}>Copiar link</LinkButton>
        </div>
        
      </AddContainer>
    </Modal>
  )
}

export default LinkModal;
