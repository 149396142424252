import axios from "./config";

export const sendLead = async payload => {
  try {
    const result = await axios.post("/lead/", payload);
    console.log(result);
    return result;
  } catch (error) {
    console.log(error.response);
    return {error: true, errorMessage: "Ocorreu um problema e não foi possível enviar seus dados"}
  }
};
