import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getReports } from "../../providers/processProvider";
import { ButtonSubmit, LinkButton } from "../../styles/buttons";
import { convertDate } from "../../utils/dates";
import Modal from "../Modal/Modal";
import { ReportItem, ReportsContainer } from "./reportConsultingStyles";

const ReportConsulting = props => {

  const user = useSelector(state => state.auth.user);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(false);
  
  useEffect(() => {
    if(!props.show) return;
    if(limit) return;
    const getData = async () => {
      const result = await getReports(props.processId, user.token, page);
      if(result.error) {
        return;
      }
      setList(state => [...state, ...result.results]);
      if(!result.next) {
        setLimit(true);
      }
    }
    getData();
  }, [props.processId, user.token, props.show, page, limit])

  const nextPage = () => {
    if(limit) return;
    setPage(page + 1);
  }

  return (
    <Modal toggle={props.toggle} isOpen={props.show}>
      <ReportsContainer>
        <h4>Relatórios</h4>

        {limit && list.length === 0 ? (
          <p className="center">Não há relatórios disponíveis</p>
        ) : null}

        <div className="report-item-list">
        {list.map(item => (
          <ReportItem key={`report-${item.id}`}>            
            <a target="_blank" rel="noopener noreferrer" className="document-link"
              href={item.document}
            >
              <LinkButton>{item.name}</LinkButton>
            </a>
            <p>Atualizado em {convertDate(item.updated_at)}</p>
          </ReportItem>
        ))}
        </div>

        {!limit ? (
          <ButtonSubmit onClick={nextPage}>
            Ver mais
          </ButtonSubmit>
        ) : null}

      </ReportsContainer>
    </Modal>
  )
}

export default ReportConsulting;
