import React, { useState } from "react";
import { LabelDraggable, Label, Input } from "../../styles/forms";
import { Grid } from "../../styles/grid";
import { SocialAuthContainer } from "./styles";

const SocialRegister = props => {

  return (
    <SocialAuthContainer>

      {/* <h3>Dados do empregador</h3>
      <p>Anexe abaixo as duas últimas declarações de imposto de renda do empregador</p>
      <LabelDraggable>
        Arraste um arquivo aqui ou clique para procurá-lo
      </LabelDraggable>
      <LabelDraggable>
        Arraste um arquivo aqui ou clique para procurá-lo
      </LabelDraggable> */}

      <h3>Dados do empregado</h3>
      <Grid>
        <Label>
        <span>Nome completo</span>
        <Input name="name" onChange={props.onChangeHandler} placeholder="Nome completo" />
      </Label>
        <Label className="col-6">
          <span>CPF</span>
          <Input name="cpf_cnpj" onChange={props.onChangeHandler} placeholder="CPF" />
        </Label>
        <Label className="col-6">
          <span>RG</span>
          <Input name="rg" onChange={props.onChangeHandler} placeholder="RG" />
        </Label>
        <Label className="col-6">
          <span>PIS</span>
          <Input name="pis" onChange={props.onChangeHandler} placeholder="PIS" />
        </Label>
        <Label className="col-6">
          <span>Título de eleitor</span>
          <Input name="titulo_de_eleitor" onChange={props.onChangeHandler} placeholder="Título de eleitor" />
        </Label>
      </Grid>
      <h4>Comprovante de residência</h4>
      <LabelDraggable
        onDragOver={e => e.preventDefault()}
        onDrop={props.onDropResidence}
      >
        {props.files.proof_of_residence.name ? (
          props.files.proof_of_residence.name
        ) : (
          "Arraste um arquivo aqui ou clique para procurá-lo"
        )}
        <Input type="file" name="proof_of_residence" onChange={props.onFileChange} />
      </LabelDraggable>
      <h4>Comprovante de escolaridade</h4>
      <LabelDraggable
        onDragOver={e => e.preventDefault()}
        onDrop={props.onDropSchoolAttendance}
      >
        {props.files.proof_of_school_attendance.name ? (
          props.files.proof_of_school_attendance.name
        ) : (
          "Arraste um arquivo aqui ou clique para procurá-lo"
        )}
        <Input type="file" name="proof_of_school_attendance" onChange={props.onFileChange} />
      </LabelDraggable>

    </SocialAuthContainer>
  )
}
export default SocialRegister;
