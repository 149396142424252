import React from "react";
import FormWizard from "../FormWizard/FormWizard";
import { ButtonPrimary } from "../../styles/buttons";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { updateProcess } from "../../providers/processProvider";

const Contract = props => {

  const user = useSelector(state => state.auth.user);
  const params = useParams();
  const history = useHistory();

  const send = async e => {
    e.preventDefault();

    const step = Number(params.step);

    const payload = {
      step: step + 1,
    }

    const result = await updateProcess(payload, params.process, user.token);
    if(result.error) {
      console.warn(result.error);
      return;
    }

    console.log(result);

    
    history.push(`/app/adicionar/abrir/${step + 1}/${params.process}`);
  }

  return (
    <FormWizard>
      <div className="content">

        <div className="content-header">
          <h2>Modelo de Contrato</h2>
        </div>

        <div className="content-body">
          <div className="scroll">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque viverra volutpat nisl, sollicitudin gravida orci auctor non. Vestibulum feugiat blandit consequat. Vivamus ullamcorper dui mattis tellus lobortis, sed ultricies augue semper. Aenean orci sem, auctor non purus sit amet, facilisis vestibulum lectus. Donec non orci nunc. Suspendisse scelerisque in nunc vitae mollis. Ut enim mauris, fermentum at est aliquam, varius sodales lacus. Cras feugiat imperdiet sem. Aenean vitae turpis maximus, imperdiet leo et, imperdiet lorem. Sed felis nulla, lacinia ut neque nec, malesuada tempus erat. Curabitur id eros eu purus interdum consequat at sit amet tellus.
<br/><br/>
Aenean aliquet nunc et finibus imperdiet. Curabitur egestas eros id dui eleifend feugiat. Vestibulum aliquet convallis aliquet. Nulla pretium massa lorem, eu porttitor mauris elementum id. Nulla ac tristique massa, ut mattis dui. Nam blandit sapien ac massa egestas, sit amet imperdiet ex viverra. Aliquam erat volutpat. Nunc tempor vitae turpis et tincidunt.
<br/><br/>
Phasellus neque sapien, molestie non odio non, ultrices fringilla lacus. Donec eu ullamcorper nisi. Donec dapibus porta elit, et auctor est. Ut dignissim vel nulla quis interdum. Donec pretium condimentum lectus vel vehicula. Morbi urna sapien, ultricies scelerisque porttitor id, eleifend id ligula. Vestibulum porta sed mauris at rhoncus. Vivamus sit amet enim risus. Duis aliquam faucibus erat, id mattis justo molestie non. Maecenas non tincidunt lacus. Etiam cursus ante sit amet ligula auctor, in aliquam purus pulvinar. Donec sed gravida diam. Donec et urna quis lectus tincidunt placerat. Suspendisse potenti.
<br/><br/>
Pellentesque euismod leo sed fermentum laoreet. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Phasellus cursus est nec dui aliquam ornare. Donec ex tortor, vestibulum sed ante in, elementum vehicula libero. Mauris ac purus laoreet, fermentum turpis eget, malesuada augue. Nullam risus sapien, blandit gravida nulla quis, finibus maximus erat. Sed commodo velit vitae consequat elementum. Ut mauris nunc, porta in mi quis, vehicula accumsan enim. Praesent suscipit elit quis elit gravida lacinia. Suspendisse at mi a nunc sollicitudin sollicitudin suscipit a nisi. Ut rutrum ullamcorper nunc, non tincidunt tellus porta ut. Cras quis risus in risus sodales pretium sit amet nec sapien. Aenean id est eget turpis finibus convallis in eget neque. Nunc sollicitudin, neque porttitor rutrum ullamcorper, felis turpis laoreet nisi, id placerat libero magna quis eros. Nulla sollicitudin volutpat ligula, sit amet elementum justo facilisis vel.
<br/><br/>
Maecenas tristique erat erat, quis pulvinar ante fringilla in. Vestibulum lorem sem, ornare eget leo vel, commodo consectetur ante. Fusce dictum hendrerit augue, ac ultrices mauris euismod eget. Donec vehicula tortor facilisis eros gravida condimentum. Vivamus quis lorem tincidunt, lobortis erat nec, posuere lectus. Pellentesque augue turpis, malesuada et gravida quis, ullamcorper eu risus. Aenean faucibus ligula quis euismod luctus. Maecenas ut hendrerit nulla. Integer dictum odio nec ante maximus, eget fringilla purus semper. Phasellus ac tellus volutpat, commodo libero nec, pretium diam. In accumsan tempus elementum. Phasellus congue odio id massa tempus accumsan.
          </div>
        </div>

      </div>

      <ButtonPrimary app className="block" onClick={send}>Avançar</ButtonPrimary>
    </FormWizard>
  )
}
export default Contract;
