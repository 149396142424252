import React, { useState } from "react";
import { CompanyContainer, CompanyButton } from "./companyStyles";

import infoIcon from "../../assets/company-info.png";
import reportIcon from "../../assets/company-report.png";
import transactionIcon from "../../assets/company-transaction.png";
import AddTransactions from "../../components/AddTransaction/AddTransactions";
import ReportConsulting from "../../components/ReportConsulting/ReportConsulting";
import PaymentHistory from "../../components/PaymentHistory/PaymentHistory";
import { Alert } from "../../styles/forms";


const Company = props => {

  const [addingTransaction, setAddingTransaction] = useState(false);
  const [showingReports, setShowingReports] = useState(false);
  const [showingPayments, setShowingPayments] = useState(false);

  const suspended = props.company.suspended;

  return (
    <>
    <CompanyContainer>
      <h3>
        {props.company.entity.is_company
          ? props.company.entity.company.fantasy_name
          : props.company.entity.name
        }
      </h3>
      <Alert className="suspended-alert" show={suspended}>Vá até pagamentos para gerar um boleto e pagar sua mensalidade ou entre em contato conosco</Alert>
      <div className="buttons">
        <CompanyButton icon={infoIcon} onClick={() => setShowingPayments(!showingPayments)}>
          Ver Pagamentos
        </CompanyButton>
        <CompanyButton icon={reportIcon} 
          onClick={() => setShowingReports(!showingReports)}
          disabled={suspended}
        >
          Consultar Relatórios
        </CompanyButton>
        <CompanyButton icon={transactionIcon}
          onClick={() => setAddingTransaction(!addingTransaction)}
          disabled={suspended}
        >
          Anexar Movimentações
        </CompanyButton>
      </div>
    </CompanyContainer>

    <AddTransactions show={addingTransaction} 
      toggle={() => setAddingTransaction(!addingTransaction)}
      process={props.company}
    />

    <ReportConsulting 
      show={showingReports}
      toggle={() => setShowingReports(!showingReports)}
      processId={props.company.id}
    />

    <PaymentHistory 
      show={showingPayments}
      toggle={() => setShowingPayments(!showingPayments)}
      processId={props.company.id}
    />

    </>
  )
}

export default Company;
