import React from "react";
import { LandingFooter, FooterContent } from "./footerStyles";
import { LinkButton, ButtonOutlined, ButtonPrimary } from "../../styles/buttons";
import { colors } from "../../styles/constants";
import { Link } from "react-router-dom";

import logo from "../../assets/logo-name-white.png";

const Footer = props => {
  return (
    <LandingFooter>
      <FooterContent>
        <div className="contact-info">
          {/* <p>contabilizar</p> */}
          <img src={logo} alt="Contabilizar"/>
          <ul>
            <li>CNPJ 00.000.000/0000-00</li>
            <li>Telefone 85 0000-0000</li>
            <li>E-mail xxxxxxxxx@xxxx.xxx</li>
          </ul>
        </div>

        <nav>
          <Link to="/">
            <LinkButton color={colors.light}>Início</LinkButton>
          </Link>
          <Link to="/planos">
            <LinkButton color={colors.light}>Planos</LinkButton>
          </Link>
          <Link to="/login">
            <ButtonOutlined color={colors.light}>Entrar</ButtonOutlined>
          </Link>
        </nav>

        <div className="footer-actions">
          <Link to="/cadastro">
            <ButtonPrimary backgroundColor={colors.secondary} color={colors.primary}>
              Cadastre-se agora
            </ButtonPrimary>
          </Link>
          <Link to="/contato-parceiro">
            <LinkButton color={colors.light}>Seja um parceiro</LinkButton>
          </Link>
        </div>
      </FooterContent>

      <p className="rights">2020 - Direitos reservados</p>
    </LandingFooter>
  )
}

export default Footer;