import React, { useState } from "react";
import { SignInContainer } from "./signInStyles";
import { Alert, Label, Input } from "../../styles/forms";

import logo from "../../assets/logo-name.png";
import { ButtonPrimary } from "../../styles/buttons";
import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { allRequiredFull } from "../../utils/validation";
import { login } from "../../providers/authProvider";
import { url } from "../../providers/config";

const initialPayload = {
  username: "",
  password: "",
}

const SingInScreen = props => {

  const dispatch = useDispatch();
  const isLogged = useSelector((state) => state.auth.isLogged);

  const [payload, setPayload] = useState(initialPayload);
  const [error, setError] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onChangeHandler = e => {
    setPayload({...payload, [e.target.name]: e.target.value})
  }

  const isValid = () => {
    if(!allRequiredFull(payload)) {
      setValidationMessage("É necessário preencher todos os campos");
      return false;
    }
    return true;
  }

  const send = async e => {
    e.preventDefault();
    if(loading) return;
    setLoading(true);
    setError("");
    setValidationMessage("");

    if(!isValid()) {
      setLoading(false);
      return;
    }

    const result = await dispatch(login(payload));
    
    if(result.error) {
      setError("Não foi possível entrar no sistema com as credenciais fornecidas");
      setLoading(false);
      return;
    }
  }

  if(isLogged) {
    return <Redirect to="/app/inicio/empresas" />;
  }

  return (
    <SignInContainer>
      <img className="logo" src={logo} alt="Logo Contabilizar" />
      <h1>Entrar</h1>
      <p>Acesse sua conta <strong>Contabilizar</strong>.</p>

      <form>
        <Label>
          <span>E-mail</span>
          <Input name="username" onChange={onChangeHandler} placeholder="E-mail" />
        </Label>
        <Label>
          <span>Senha</span>
          <Input name="password" onChange={onChangeHandler} placeholder="Senha" type="password" />
        </Label>
        <a className="recover-password" target="_blank" rel="noopener noreferrer" 
          href={`${url}/accounts/password_reset`}
        >
          Esqueci minha senha
        </a>

        <Alert show={validationMessage}>{validationMessage}</Alert>
        <Alert show={error}>{error}</Alert>

        <ButtonPrimary onClick={send}>
          {loading ? "Carregando..." : "Entrar"}
        </ButtonPrimary>
      </form>

      <p className="register-link">Ainda não possui conta? <Link to="/cadastro">Clique aqui!</Link></p>
    </SignInContainer>
  )
}

export default SingInScreen;
