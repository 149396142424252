import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCompanyList } from "../../providers/processProvider";
import { Alert } from "../../styles/forms";
import CompanyList from "./CompanyList";
import ProcessList from "./ProcessList";

const CompanyListScreen = props => {

  const user = useSelector(state => state.auth.user);
  const [companies, setCompanies] = useState([]);
  const [processes, setProcesses] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {

      if(loading) return;
      setLoading(true);
      setError("");

      const result = await getCompanyList(user.id, user.token);
      console.log(result);
      setLoading(false);

      if(result.error) {
        setError(result.error);
        return;
      }

      setCompanies(result.completed);
      setProcesses(result.incompleted);

    }

    getData();
    
  }, [user.id, user.token])

  return (
    <>
      <Alert show={error}>{error}</Alert>

      {processes?.length > 0 ? <ProcessList list={processes} /> : null}
      {companies?.length > 0 ? <CompanyList list={companies} /> : null}

    </>
  )
}

export default CompanyListScreen;
