import React from "react";
import ProcessUpdate from "../../components/ProcessUpdate/ProcessUpdate";
import { ListContainer } from "../ProcessesListScreens/processesListStyles";

const ProcessList = props => {


  return (
    <ListContainer>
      <h3>Abertura e migração de empresas</h3>
      {props.list.map(item => (
        <ProcessUpdate 
          key={`process-${item.id}`} 
          process={item}
        />
      ))}
    </ListContainer>
  )
}

export default ProcessList;
