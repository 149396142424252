import React, { useState } from "react";
import FormWizard from "../FormWizard/FormWizard";
import { ButtonPrimary, ButtonSubmit } from "../../styles/buttons";
import { useParams, useHistory } from "react-router-dom";
import { Alert, LabelDraggable } from "../../styles/forms";
import { updateProcess } from "../../providers/processProvider";
import { useSelector } from "react-redux";
import ModalMessage from "../ModalMessage/ModalMessage";

const initialCertificate = {
  name: "",
}

const initialMessage = {
  title: "",
  message: "",
}

const AddDigitalCertificate = props => {

  const user = useSelector(state => state.auth.user);
  const params = useParams();
  const history = useHistory();
  const [certificate, setCertificate] = useState(initialCertificate);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState(initialMessage);

  const next = async e => {
    e.preventDefault();
    const step = Number(params.step);
    const nextStep = step + 1;
    const result = await updateProcess({step: nextStep}, params.process, user.token);
    if(result.error) {
      setError("Não foi possível continuar. Verifique sua conexão e tente novamente");
      return;
    }
    
    history.push(`/app/adicionar/migrar/${nextStep}/${params.process}`);
  }

  const onFileChange = e => {
    const file = e.target.files[0];
    if(!file) {
      return;
    }
    sendCertificate(file);
  }

  const sendCertificate = async (file) => {
    if(loading) return;

    setLoading(true);
    setError("");
    setMessage({
      title: file ? "Envio de certificado" : "Solicitação de voucher",
      message: "Aguarde um momento..."
    })

    let formData = new FormData();
    formData.append("digital_certificate", file);

    const result = await updateProcess(formData, params.process, user.token);
    console.log(result);
    setLoading(false);
    
    if(result.error) {
      setError(result.errorMessage);
      cleanMessage();
      return;
    }

    if(!file) {
      setMessage({
        title: "Solicitação de voucher",
        message: "Um voucher para seu novo certificado digital foi enviado para seu e-mail cadastrado"
      })
      return;
    }
    setCertificate(file);
    setMessage({
      title: "Envio de certificado",
      message: "Seu certificado foi enviado com sucesso! Avance para a próxima etapa"
    })
  }

  const generateVoucher = e => {
    e.preventDefault();
    sendCertificate(false);
  }

  const onDropFile = e => {
    e.preventDefault();

    if (e.dataTransfer.items) {
      
        if (e.dataTransfer.items[0].kind === 'file') {
          const file = e.dataTransfer.items[0].getAsFile();
          console.log(file);
          sendCertificate(file);
        }
      
    } else {
        console.log(e.dataTransfer.files[0]);
        sendCertificate(e.dataTransfer.files[0]);
    }
  }

  const cleanMessage = () => {
    setMessage(initialMessage);
  }

  return (
    <>
    <FormWizard>
      <div className="content">

        <div className="content-header">
          <h2>Certificado Digital</h2>
        </div>

        <div className="content-body">
          <Alert show={error}>{error}</Alert>
          <LabelDraggable
            onDragEnter={() => console.log("drag enter")}
            onDragLeave={() => console.log("drag leave")}
            onDragOver={e => e.preventDefault()}
            onDrop={onDropFile}
          >
            {certificate.name 
              ? certificate.name 
              : "Arraste um arquivo ou clique para adicionar seu certificado digital"
            }
            <input type="file" onChange={onFileChange} />
          </LabelDraggable>
          <h3>Não possui certificado digital?</h3>
          <p>Clique no botão abaixo para solicitar um certificado digital gratuitamente</p>
          <ButtonSubmit onClick={generateVoucher}>
            Gerar voucher
          </ButtonSubmit>
        </div>

      </div>

      <ButtonPrimary disabled={certificate.name === ""} app className="block" onClick={next}>
        Avançar
      </ButtonPrimary>
    </FormWizard>
    <ModalMessage 
      {...message}
      show={message.title !== "" || message.message !== ""}
      toggle={cleanMessage}
    />
    </>
  )
}
export default AddDigitalCertificate;
