// Action Types

export const Types = {
  LOGIN: 'auth/LOGIN',
  LOGOUT: 'auth/LOGOUT',
  EDIT_USER: 'auth/EDIT_USER',
};

// Reducer

const initialState = {
  isLogged: false,
  user: {},
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case Types.LOGIN:
      return { ...state, user: action.payload.user, isLogged: true };
    case Types.LOGOUT:
      return { ...state, user: {}, isLogged: false };
      case Types.EDIT_USER:
      return { ...state, user: action.payload.user };
    default:
      return state;
  }
}

// Action Creators

export function loginSuccess(user) {
  localStorage.setItem("user", JSON.stringify(user));
  return {
    type: Types.LOGIN,
    payload: {
      user,
    },
  }
}

export function logout() {
  localStorage.removeItem("user");
  return {
    type: Types.LOGOUT,
  }
}

export function editUserSuccess(user) {
  localStorage.setItem("user", JSON.stringify(user));
  return {
    type: Types.LOGIN,
    payload: {
      user,
    },
  }
}
