import styled from "styled-components";
import { colors } from "../../styles/constants";
import { Button } from "../../styles/buttons";
import { pseudoElementImage } from "../../styles/mixins";

export const CompanyContainer = styled.div`

  max-width: 560px;
  margin: auto;
  margin-bottom: 1rem;

  h3 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1.175rem;
  }

  .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 0.75rem;
    align-items:center;
    justify-items: stretch;
    width: 100%;
    border: 1px solid ${colors.primary};
    border-radius: 3px;
    padding: 0.75rem;
    overflow-x: auto;
  }

  .suspended-alert {
    margin-bottom: 1rem;
  }

  @media (min-width: 590px) {
    .buttons {
      column-gap: 1.5rem;
      padding: 1.5rem;
    }
  }
`;

export const CompanyButton = styled(Button)`
  text-transform: none;
  font-size: 0.85rem;
  font-weight: 500;
  border: 1px solid ${colors.secondary};
  padding: 0.5rem 0.5rem;
  border-radius: 3px;
  min-width: 105px;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &::before {
    ${pseudoElementImage}
    background-image: ${props => `url(${props.icon})`};
    width: 1.2rem;
    height: 1.2rem;
    margin-bottom: 0.5rem;
  }

  @media (min-width: 590px) {
    font-size: 1rem;
    padding: 1rem;
    min-height: 100px;
  }
`;
