import React from 'react';
import { AppContainer } from '../styles/appStyles';
import AppHeader from '../components/Header/AppHeader';
import Chatbot from '../components/Chatbot/Chatbot';

const AppLayout = ({children, ...rest}) => {
  return (
    <>
      <AppHeader />
      <AppContainer {...rest}>
        {children}
        <Chatbot />
      </AppContainer>
    </>
  );
}

export default AppLayout;
