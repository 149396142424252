import React, { useState } from "react";
import ChatbotKit from "react-chatbot-kit";

import config from "./config";
import ActionProvider from "./ActionProvider";
import MessageParser from "./MessageParser";
import { ChatbotContainer, ChatbotButton, ChatbotHeader } from "./chatbotStyles";

const Chatbot = props => {

  const [visible, setVisible] = useState(false);

  return (
    <ChatbotContainer>

      {visible ? (
        <>
          <ChatbotHeader>Converse com a Contabilizar</ChatbotHeader>
          <ChatbotKit 
            config={config} 
            actionProvider={ActionProvider}
            messageParser={MessageParser}
          />
      </>
      ) : null}
      
      <ChatbotButton onClick={() => setVisible(!visible)}>Chat</ChatbotButton>

    </ChatbotContainer>
  )
}

export default Chatbot;
