import React from "react";
import { AdvantageContent } from "./homeStyles";

const AdvantageCard = props => {
  return (
    <AdvantageContent>
      <img src={props.icon} alt={props.title}/>
      <h3>{props.title}</h3>
      <p>{props.text}</p>
    </AdvantageContent>
  )
}

export default AdvantageCard;
