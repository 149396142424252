import styled from "styled-components";
import { colors } from "../../styles/constants";

export const AppHeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.primary};
  padding: 0.5rem 1rem;
  position: fixed;
  width: 100%;
  height: var(--header-height);

  nav {
    display: flex;
    button {
      color: ${colors.light};
      align-items: flex-end;
    }
  }

  .logo {
    margin: 0.75rem 0;
  }

  a {
    text-decoration: none;
  }

  @media (max-width: 580px) {
    nav button {
      font-size: 0;
      padding: 0;
      margin: 0.75rem;
      &::before {
        margin-right: 0;
      }
    }
  }

`;

export const LandingHeader = styled.header`
  --header-height: 60px;
  display: grid;
  grid-template-columns: 100px auto 100px;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.light};
  position: fixed;
  width: 100%;
  height: var(--header-height);
  box-shadow: 0px 0px 3px 0px rgba(46,65,127,0.4);
  @media (min-width: 580px) {
    grid-template-columns: 100px auto;
    #menu-btn {
      display: none;
    }
  }
`

export const NavMenu = styled.nav`
  display: inline-block;
  
  @media (max-width: 579px) {
    display: ${({ show = false }) => show ? "block" : "none"};
    position: fixed;
    width: 100vw;
    top: var(--header-height);
    height: calc(100vh - var(--header-height));
    left: 0;
    flex-direction: column;
    padding: 1rem;
    background-color: ${colors.primary};
    #login-btn {
      border: none;
      margin: 0;
    }
    & > * {
      display: block;
    }
    & > *, & > * button {
      color: ${colors.light};
      border-color: ${colors.light};
    }
  }
`;
