import React from "react";
import Modal from "../Modal/Modal";
import { MessageContainer, MessageTitle, MessageText } from "./modalMessageStyles";

const ModalMessage = props => {
  return (
    <Modal toggle={props.toggle} isOpen={props.show}>
      <MessageContainer>
        <MessageTitle>
          {props.title}
        </MessageTitle>
        <MessageText>
          {props.message}
        </MessageText>
      </MessageContainer>
    </Modal>
  )
}

export default ModalMessage;
