import React from "react";
import { ProcessUpdateContainer } from "./processUpdateStyles";
import { Link } from "react-router-dom";
import { LinkButton } from "../../styles/buttons";
import { convertDate } from "../../utils/dates";

const OPENING_INITIAL_STEP = 2;
const MIGRATION_INITIAL_STEP = 2;

const showStepMessage = (isCompany, isOpening, step) => {
  //show step message according to is_opening status and step number

  if(!isCompany) {
    switch (step) {
      case 1:
        return `Continue o cadastro de empregado`;
      case 2:
        return `Aguardando pagamento`;
    
      default:
        return `Continue o cadastro de empregado`
    }
  }

  if(isOpening) {
    switch (step) {
      case 3:
        return `Adicione seu endereço fiscal`;
      case 4:
        return `Adicionando sócios`;
      case 5:
        return `Veja o modelo de contrato social e siga para o pagamento`;
      case 6:
        return `Aguardando pagamento`;
    
      default:
        return `Adicione as informações básicas de sua empresa`
    }
  }

  switch (step) {
    case 4:
      return `Adicionando certificado digital`;
    case 5:
      return `Adicione os dados do antigo contador`;
    case 6:
      return `Informações de cadastro na prefeitura`;
    case 7:
      return `Aguardando pagamento`;
  
    default:
      return `Adicione as informações básicas de sua empresa`
  }

}

const getLink = (isCompany, isOpening, step, processId) => {
  const url = `/app/adicionar/`;
  if(!isCompany) {
    return `${url}empregado/${step}/${processId}`;
  }
  if(isOpening) {
    const actualStep = step <= OPENING_INITIAL_STEP ? OPENING_INITIAL_STEP : step;
    return `${url}abrir/${actualStep}/${processId}`;
  }
  const actualStep = step <= MIGRATION_INITIAL_STEP ? MIGRATION_INITIAL_STEP : step;
  return `${url}migrar/${actualStep}/${processId}`;
}

const getName = (process) => {
  if(process.name) {
    return process.name;
  }

  if(process.entity.is_company) {
    if(process.is_opening) {
      return "Abertura de empresa";
    }
    return "Migração de empresa";
  }

  return "Cadastro de empregado"
  
}

const ProcessUpdate = ({ process }) => {

  return (
    <ProcessUpdateContainer>
      <h3 className="process-header">
        {getName(process)}
        {" "}-{" "} 
        {convertDate(process.entity.updated_at)}
      </h3>
      <p>{showStepMessage(process.entity.is_company, process.is_opening, process.step)}</p>
      <Link 
        to={getLink(process.entity.is_company, process.is_opening, process.step, process.id)}
      >
        <LinkButton>
          Continuar
        </LinkButton>
      </Link>
    </ProcessUpdateContainer>
  )

}

export default ProcessUpdate;
