import styled from "styled-components";
import { colors } from "../../styles/constants";

export const DotNavigator = styled.nav`
  /* max-width: 560px; */
  margin: 1.5rem auto;
  display: flex;
  justify-content: center;

  a {
    margin: 0 0.25rem;
    width: 0.8rem;
    height: 0.8rem;
    background-color: ${colors.gray};
    border-radius: 50%;
    cursor: unset;
  }

  a.active {
    background-color: ${colors.secondary};
  }
`
