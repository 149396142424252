import styled from "styled-components";
import { colors } from "./constants";

export const Button = styled.button`
  box-sizing: border-box;
  color: ${({ color = colors.dark }) => color};
  text-decoration: ${({ underline = false }) =>
    underline ? "underline" : "none"};
  cursor: pointer;
  font-size: ${({ fontSize = "1rem" }) => `${fontSize}`};
  padding: 0.75rem 1rem;
  text-transform: uppercase;
  border-radius: 1.5rem;
  a {
    color: inherit;
    text-decoration: none;
    font-weight: inherit;
    text-decoration: ${({ underline = false }) =>
      underline ? "underline" : "none"};
  }
  opacity: ${({ disabled = false }) => disabled ? "50%" : "100%"};
`;

export const LinkButton = styled(Button)`
  text-decoration: underline;
  text-transform: none;
  /* margin: ${({ inline = false }) => inline ? "0" : "inherit"}; */
  padding: ${({ inline = false }) => inline ? "0" : "0.75rem 1rem"};
  display: ${({ inline = false }) => inline ? "inline" : "inherit"};
`;

export const Link = styled.a`
  color: ${colors.dark};
  text-decoration: ${({ underline = false }) =>
    underline ? "underline" : "none"};
  &.color-primary {
    color: ${colors.primary};
  }
`;

export const IconButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  text-transform: none;
  text-decoration: none;
  &::before {
    content: "";
    margin-right: 0.5rem;
    background-image: ${props => `url(${props.icon})`};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    width: ${({ width = "1.3rem" }) => `${width}`};
    height: ${({ height = "1.3rem" }) => `${height}`};
  }
`;

export const ImageButton = styled(Button)`
  font-size: 0;
  padding: 0;
  margin: 0.75rem 1rem;
  width: ${({ width = "1.5rem" }) => `${width}`};
  height: ${({ height = "1.5rem" }) => `${height}`};
  background-image: ${props => `url(${props.icon})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ButtonPrimary = styled(Button)`
  background-color: ${({ backgroundColor = colors.secondary }) => `${backgroundColor}`};
  color: ${({ color = colors.primary }) => `${color}`};
  margin: 0 1rem;
  max-height: 42px;
  font-weight: 500;
  border-radius: ${({ app = false }) => app ? '5px' : '1.5rem'};
  text-transform: ${({ app = false }) => app ? 'none' : 'uppercase'};
  min-width: 120px;
`;

// export const ButtonAppPrimary = styled(Button)`
//   background-color: ${({ backgroundColor = colors.secondary }) => `${backgroundColor}`};
//   color: ${({ color = colors.primary }) => `${color}`};
//   margin: 0 1rem;
//   max-height: 42px;
//   font-weight: 500;
//   display: block;
//   border-radius: 5px;
//   text-transform: none;
//   min-width: 120px;
// `;

export const ButtonOutlined = styled(Button)`
  border: 2px solid ${colors.primary};
  border-color: ${({ color = colors.primary }) => `${color}`};
  color: ${({ color = colors.primary }) => `${color}`};
  margin: 0 1rem;
  max-height: 42px;
`;

export const ButtonSubmit = styled(ButtonOutlined)`
  border-color: ${colors.secondary};
  border-radius: 3px;
  min-width: 130px;
  text-transform: none;
  margin-left: auto;
  margin-right: 0;
  display: block;
`;
