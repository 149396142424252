import React from "react";
import { MainSection, AdvantagesSection, AdvantagesGrid, Banner } from "./homeStyles";
import { ButtonPrimary, ButtonOutlined } from "../../styles/buttons";
import AdvantageCard from "./AdvantageCard";
import { Link } from "react-router-dom";

import advantage1 from "../../assets/nota-fiscal-icon.png";
import advantage2 from "../../assets/notificacao-icon.png";
import advantage3 from "../../assets/suporte-icon.png";
import bannerImg from "../../assets/banner.png";
import LeadSection from "./LeadSection";

const HomeScreen = props => {
  return (
    <>
      <MainSection>
        <Banner>
          <img src={bannerImg} alt="Usando a Contabilizar"/>
        </Banner>

        <div className="content">
          <h1 className="main-title">
            Contabilidade para <br/>
            sua empresa de uma <br/>
            <span className="marked">nova forma.</span>
          </h1>
          <p>Esqueça sua antiga calculadora, aqui na <strong>Contabilizar</strong> temos:</p>
          <ul>
            <li>Praticidade</li>
            <li>Segurança</li>
            <li>Especialistas</li>
          </ul>
        </div>

        <div className="cta-container">
          <a href="#contato">
            <ButtonPrimary>Entre em contato</ButtonPrimary>
          </a>
          <Link to="/planos">
            <ButtonOutlined>Conheça nossos planos</ButtonOutlined>
          </Link>
        </div>
      </MainSection>

      <LeadSection />

      <AdvantagesSection>
        <h2>Vantagens</h2>
        <AdvantagesGrid>
          <AdvantageCard
            title="Emissão de notas fiscais"
            icon={advantage1}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus in egestas magna. Duis quis rutrum sem."
          ></AdvantageCard>
          <AdvantageCard
            title="Controle de impostos e rotinas"
            icon={advantage2}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus in egestas magna. Duis quis rutrum sem."
          ></AdvantageCard>
          <AdvantageCard
            title="Suporte especializado"
            icon={advantage3}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus in egestas magna. Duis quis rutrum sem."
          ></AdvantageCard>
        </AdvantagesGrid>
      </AdvantagesSection>

    </>
  )
}

export default HomeScreen;
