import axios from "axios";

//export const url = "https://contabilizar-api.devaribox.co"

export const url = "https://contabilizar-api.devari.com.br"

let baseUrl = url + "/api/v1";

const config = axios.create({
  baseURL: baseUrl
});

console.log("process env", process.env.NODE_ENV);

let siteUri = "http://contabilizar-dev.devaribox.co/";
//let siteUri = "http://contabilizar.devari.com.br/#/";

if(process.env.NODE_ENV === "development") {
  siteUri = "http://contabilizar-dev.devaribox.co/"
}

export const siteUrl = siteUri;

export default config;
