import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getProcess } from "../../providers/processProvider";
import Payment from "../Payment/Payment";

const OpenPayment = props => {

  const user = useSelector(state => state.auth.user);
  const params = useParams();
  const history = useHistory();
  const [price, setPrice] = useState("");

  const next = () => {
    //const step = Number(params.step);
    history.push(`/app/`);
  }

  useEffect(() => {
    const getData = async () => {
      const result = await getProcess(params.process, user.token);
      console.log(result.plan.price);
      setPrice(result.plan.price);
    }
    getData();
  }, [])

  return (
    <Payment next={next} price={price} />
  )
}
export default OpenPayment;
