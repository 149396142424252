import React, { useState, useEffect } from "react";
import { Label, Input, Alert } from "../../styles/forms";
import { LinkButton, ButtonSubmit } from "../../styles/buttons";
import { ProfileContainer } from "./profileStyles";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/ducks/auth";
import { editUser } from "../../providers/authProvider";
import { passwordMatch } from "../../utils/validation";
import { Grid } from "../../styles/grid";

const initialAddressPayload = {
  street: "",
  number: "",
  district: "",
  city: "",
  state: "",
  postcode: "",
  complement: "",
}

const ProfileScreen = props => {

  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.auth.user);
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
  });
  const [address, setAddress] = useState(initialAddressPayload);
  const [changes, setChanges] = useState({});
  const [addressChanges, setAddressChanges] = useState({});
  const [errorInfo, setErrorInfo] = useState("");
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [errorAddress, setErrorAddress] = useState("");
  const [loadingAddress, setLoadingAddress] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [loadingPassword, setLoadingPassword] = useState(false);

  useEffect(() => {
    setForm({
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      phone: userInfo.phone,
      email: userInfo.email,
    });
    setAddress({
      ...userInfo.address
    })
  }, [userInfo]);

  const handleChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
    setChanges({
      ...changes,
      [e.target.name]: e.target.value
    });
  };

  const onChangeAddressHandler = e => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value
    });
    setAddressChanges({
      ...addressChanges,
      [e.target.name]: e.target.value
    });
  }

  const send = async e => {
    e.preventDefault();
    if(loadingInfo) return;
    setLoadingInfo(true);
    setErrorInfo("");

    console.log(changes);

    const result = await dispatch(editUser(userInfo.id, changes, userInfo.token));
    setLoadingInfo(false);
    if(result.error) {
      setErrorInfo("Não foi possível editar suas informações");
      return;
    }
  }

  const sendAddress = async e => {
    e.preventDefault();
    if(loadingAddress) return;
    setLoadingAddress(true);
    setErrorAddress("");

    const payload = {
      address_data: {
        ...addressChanges
      }
    }

    const result = await dispatch(editUser(userInfo.id, payload, userInfo.token));
    setLoadingAddress(false);
    if(result.error) {
      setErrorAddress("Não foi possível editar sueu endereço");
      return;
    }
  }

  const changePassword = async e => {
    e.preventDefault();
    if(loadingPassword) return;

    //password validation
    if(!passwordMatch(password, confirmPassword)) {
      setErrorPassword("Verifique se digitou sua nova senha corretamente");
      return;
    }

    setLoadingPassword(true);
    setErrorPassword("");

    console.log(changes);

    const result = await dispatch(editUser(userInfo.id, {password}, userInfo.token));
    setLoadingPassword(false);
    if(result.error) {
      setErrorPassword("Não foi possível editar suas informações");
      return;
    }
  }

  return (
    <ProfileContainer>
      <form>
        <h1>Editar Conta</h1>
        <Label>
          <span>Nome</span>
          <Input name="first_name" value={form.first_name} onChange={handleChange} placeholder="Nome" />
        </Label>
        <Label>
          <span>Sobrenome</span>
          <Input name="last_name" value={form.last_name} onChange={handleChange} placeholder="Sobrenome" />
        </Label>
        <Label>
          <span>E-mail</span>
          <Input name="email" value={form.email} disabled placeholder="E-mail" />
        </Label>
        <Label>
          <span>Telefone</span>
          <Input name="phone" value={form.phone} onChange={handleChange} placeholder="Telefone" />
        </Label>
        <Alert show={errorInfo}>{errorInfo}</Alert>
        <ButtonSubmit onClick={send}>
          {loadingInfo ? "Carregando..." : "Salvar"}
        </ButtonSubmit>
      </form>
      <form>
        <h2>Alterar senha</h2>
        <Label>
          <span>Nova Senha</span>
          <Input onChange={e => setPassword(e.target.value)} placeholder="Nova Senha" type="password" />
        </Label>
        <Label>
          <span>Confirmar Nova senha</span>
          <Input onChange={e => setConfirmPassword(e.target.value)} placeholder="Confirmar nova senha" type="password" />
        </Label>
        <Alert show={errorPassword}>{errorPassword}</Alert>
        <ButtonSubmit disabled={password === "" || confirmPassword === ""} onClick={changePassword}>
          {loadingPassword ? "Carregando..." : "Alterar"}
        </ButtonSubmit>
      </form>

      <form>
        <h2>Editar endereço</h2>
        <Grid className="grid-container">

          <Label className="col-9">
            <span>Rua</span>
            <Input value={address.street} name="street" onChange={onChangeAddressHandler} placeholder="Rua" />
          </Label>
          <Label className="col-3">
            <span>Número</span>
            <Input value={address.number} name="number" onChange={onChangeAddressHandler} placeholder="Número" />
          </Label>
          <Label>
            <span>Complemento</span>
            <Input value={address.complement} name="complement" onChange={onChangeAddressHandler} placeholder="Complemento" />
          </Label>
          <Label className="col-6">
            <span>CEP</span>
            <Input value={address.postcode} name="postcode" onChange={onChangeAddressHandler} placeholder="CEP" />
          </Label>
          <Label className="col-6">
            <span>Bairro</span>
            <Input value={address.district} name="district" onChange={onChangeAddressHandler} placeholder="Bairro" />
          </Label>
          <Label className="col-6">
            <span>Cidade</span>
            <Input value={address.city} name="city" onChange={onChangeAddressHandler} placeholder="Cidade" />
          </Label>
          <Label className="col-6">
            <span>Estado</span>
            <Input value={address.state} name="state" onChange={onChangeAddressHandler} placeholder="Estado" />
          </Label>
          
        </Grid>
        <Alert show={errorAddress}>{errorAddress}</Alert>
        <ButtonSubmit onClick={sendAddress}>
          {loadingAddress ? "Carregando..." : "Salvar"}
        </ButtonSubmit>
      </form>

      <LinkButton onClick={() => dispatch(logout())} className="link">Sair da conta</LinkButton>
    </ProfileContainer>
  )
}

export default ProfileScreen;