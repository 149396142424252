import React, { useState } from "react";
import { Button, ButtonPrimary, ButtonOutlined, ImageButton } from "../../styles/buttons";
import { LandingHeader, NavMenu } from "./headerStyles";
import { Link } from "react-router-dom";

import Logo from "../../assets/logo-symbol.png";

const Header = props => {
  const [showNav, setShowNav] = useState(false);
  return (
    <LandingHeader>
      <ImageButton width="38px" height="38px" icon={Logo}>
        <Link to="/">
          Contabilizar
        </Link>
      </ImageButton>

      <div>
        <NavMenu show={showNav}>
          <Link to="/" onClick={() => setShowNav(!showNav)}>
            <Button>Início</Button>
          </Link>
          <Link to="/planos" onClick={() => setShowNav(!showNav)}>
            <Button>Planos</Button>
          </Link>
          <Link to="/login" onClick={() => setShowNav(!showNav)}>
            <ButtonOutlined id="login-btn">Entrar</ButtonOutlined>
          </Link>
        </NavMenu>
        <Link to="/cadastro">
          <ButtonPrimary>Começar</ButtonPrimary>
        </Link>
      </div>
      
      <Button id="menu-btn" onClick={() => setShowNav(!showNav)}>
        {showNav ? "Fechar" : "Menu"}
      </Button>
      
    </LandingHeader>
  )
}

export default Header;
