import React from "react";
import { Route, NavLink } from "react-router-dom";
import { TabNavigator } from "../components/TabNavigator/TabNavigator";
import CompanyListScreen from "../screens/CompanyListScreen/CompanyListScreen";
import OpenProcessList from "../screens/ProcessesListScreens/OpenProcessList";
import MigrationProcessList from "../screens/ProcessesListScreens/MigrationProcessList";
import EmployeeListScreen from "../screens/EmployeeListScreen/EmployeeListScreen";

const routes = [
  { path: '/app/inicio/empresas', component: CompanyListScreen, exact: true },
  { path: '/app/inicio/empregados', component: EmployeeListScreen, exact: true },
  // { path: '/app/inicio/abertura', component: OpenProcessList, exact: true },
  // { path: '/app/inicio/migracao', component: MigrationProcessList, exact: true },
];

const ProcessesLayout = props => {

  return (
    <>
      <TabNavigator>
        <NavLink to="/app/inicio/empresas">
          Empresas
        </NavLink>
        <NavLink to="/app/inicio/empregados">
          Empregados
        </NavLink>
        {/* <NavLink to="/app/inicio/abertura">
          Abertura
        </NavLink>
        <NavLink to="/app/inicio/migracao">
          Migração
        </NavLink> */}
      </TabNavigator>

      <main>
        {routes.map(route => (
          <Route key={route.path} {...route} />
        ))}
      </main>
    </>
  )
}

export default ProcessesLayout;
