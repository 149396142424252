import React, { useState } from "react";
import FormWizard from "../FormWizard/FormWizard";
import { ButtonPrimary } from "../../styles/buttons";
import { useParams, useHistory } from "react-router-dom";
import { Label, Input, Alert } from "../../styles/forms";
import { Grid } from "../../styles/grid";
import { allRequiredFull } from "../../utils/validation";
import { updateProcess } from "../../providers/processProvider";
import { useSelector } from "react-redux";

const initialPayload = {
  cityhall_access_code: "",
  cityhall_password: "",
}

const PrefectureRegister = props => {

  const user = useSelector(state => state.auth.user);
  const params = useParams();
  const history = useHistory();
  const [payload, setPayload] = useState(initialPayload);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onChangeHandler = e => {
    setPayload({...payload, [e.target.name]: e.target.value});
  }

  const next = () => {

    const step = Number(params.step);
    
    history.push(`/app/adicionar/migrar/${step + 1}/${params.process}`);
  }

  const send = async e => {
    e.preventDefault();
    if(loading) return;

    if(!allRequiredFull(payload)) {
      setError("Complete todos os campos obrigatórios para avançar");
      return;
    }

    setLoading(true);
    setError("");

    const data = {
      step: 7,
      entity_data: {
        id: params.process,
        company_data: {
          ...payload,
        }
      }
    }

    const result = await updateProcess(data, params.process, user.token);
    console.log(result);
    setLoading(false);
    
    if(result.error) {
      setError(result.errorMessage);
      return;
    }

    next();
  }

  return (
    <FormWizard>
      <div className="content">

        <div className="content-header">
          <h2>Cadastro na prefeitura</h2>
          <p>Nos informe abaixo os dados de acesso do cadastro da empresa em sua prefeitura</p>
        </div>

        <Grid className="content-body">

          <Label>
            <span>Código de acesso</span>
            <Input name="cityhall_access_code" placeholder="Código de acesso" onChange={onChangeHandler} />
          </Label>
          <Label>
            <span>Senha</span>
            <Input type="password" name="cityhall_password" placeholder="Senha" onChange={onChangeHandler} />
          </Label>
          
        </Grid>
        
      </div>

      <Alert show={error}>{error}</Alert>

      <ButtonPrimary app className="block" onClick={send}>
        {loading ? "Carregando..." : "Avançar"}
      </ButtonPrimary>
    </FormWizard>
  )
}

export default PrefectureRegister;
