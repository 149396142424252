// provides functionality to interpret messages from the user and call the apropriate response
class MessageParser {
  constructor(actionProvider) {
    this.actionProvider = actionProvider;
  }

  // function to provide a response based on a given message
  parse(message) {

    const lowerCaseMessage = message.toLowerCase();
    
    if(lowerCaseMessage.includes("planos")) {
      this.actionProvider.handlePlans();
      return;
    }

    if(lowerCaseMessage) {
      this.actionProvider.respond();
    }
  }
}

export default MessageParser;
