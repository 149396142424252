import React, { useEffect, useState } from "react";
import PlanCard from "./PlanCard";
import { PlansContainer, PlansGrid } from "./plansStyles";
import { getPlans } from "../../providers/planProvider";
import { Alert } from "../../styles/forms";

const PlansScreen = props => {

  const [plans, setPlans] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const getData = async () => {
      const data = await getPlans();
      if(data.error) {
        setError(data.error);
        return;
      }
      console.log(data);
      setPlans(data);
    }
    getData();
  }, [])

  return (
    <PlansContainer>
      <h1>Planos e Serviços</h1>
      <p>Escolha o <strong>plano ideal</strong> para você</p>
      <Alert show={error}>{error}</Alert>
      <PlansGrid>
        {plans.map(plan => (
          <PlanCard plan={plan} key={`plan-${plan.id}`}/>
        ))}
      </PlansGrid>
    </PlansContainer>
  )
}

export default PlansScreen;
