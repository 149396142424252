import React, { useState } from "react";
import { ButtonPrimary } from "../../styles/buttons";
import { Label, Input, Form, Textarea, Alert } from "../../styles/forms";
import { ContactContainer } from "./contactProfessionalStyles";
import { sendEmail } from "../../providers/devariMailProvider";
import ModalMessage from "../../components/ModalMessage/ModalMessage";

const initialPayload = {
  name: "",
  sender: "",
  phone: "",
  message: ""
}

const ContactProfessionalScreen = props => {
  const [payload, setPayload] = useState(initialPayload);
  const [error, setError] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const send = async e => {
    e.preventDefault();
    console.log(payload);
    
    if(loading) return;
    setLoading(true);
    setError("");
    setValidationMessage("");
    if(!isValid()) {
      setValidationMessage("É necessário preencher todos os campos");
      setLoading(false);
      return;
    }
    
    const result = await sendEmail(payload);
    setLoading(false);
    if(result.error) {
      setError(result.errorMessage);
      return;
    }
    setPayload(initialPayload);
    setShowSuccessMessage(true);
  }

  const isValid = () => {
    return !Object.values(payload).some(field => field === "");
  }

  const onChangeHandler = e => {
    setPayload({...payload, [e.target.name]: e.target.value})
  }

  return (
    <ContactContainer>
      <h1>Seja um parceiro</h1>
      <p>Nos envie uma mensagem e entenda como ser um <strong>parceiro Contabilizar</strong></p>
      <Form>
        <Label>
          <span>Nome</span>
          <Input name="name" placeholder="Nome" onChange={onChangeHandler} />
        </Label>
        <Label>
          <span>E-mail</span>
          <Input name="sender" placeholder="E-mail" onChange={onChangeHandler} />
        </Label>
        <Label>
          <span>Telefone</span>
          <Input name="phone" placeholder="Telefone" onChange={onChangeHandler} />
        </Label>
        <Label>
          <span>Mensagem</span>
          <Textarea name="message" rows="5" placeholder="Mensagem" onChange={onChangeHandler} ></Textarea>
        </Label>

        <Alert show={error}>{error}</Alert>
        <Alert show={validationMessage}>{validationMessage}</Alert>

        <ButtonPrimary onClick={send}>
          {loading ? "Carregando..." : "Enviar"}
        </ButtonPrimary>
      </Form>
      <ModalMessage 
        title={"Enviado com sucesso"}
        message={"A Contabilizar entrará em contato em breve!"}
        show={showSuccessMessage}
        toggle={() => setShowSuccessMessage(!showSuccessMessage)}
      />
    </ContactContainer>
  )
}

export default ContactProfessionalScreen;