import React from 'react';
import "./styles/app.css";
import Routes from './Routes';
import { useDispatch } from 'react-redux';
import { loginSuccess } from './store/ducks/auth';



function App() {
  const dispatch = useDispatch();

  const userStored = localStorage.getItem("user");
  if(userStored) {
    dispatch(loginSuccess(JSON.parse(userStored)));
  }
  
  return (
      <Routes />
  );
}

export default App;
