import React from "react";
import { ListContainer } from "../ProcessesListScreens/processesListStyles";
import Company from "../CompanyListScreen/Company";

const EmployeeList = props => {

  return (
    <ListContainer>
      <h3>Empregados cadastrados</h3>
      {props.list.map(item => (
        <Company key={`company-${item.id}`} company={item} />
      ))}
    </ListContainer>
  )
}

export default EmployeeList;
