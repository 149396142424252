import axios from "./config";

export const getPlans = async () => {
  try {
    const result = await axios.get(`/plan/`);
    //console.log(result);
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error.response ? error.response : "Ocorreu um problema com a rede" };
  }
};
