import styled from "styled-components";
import { colors } from "./constants";

export const AuthPage = styled.div`
  h1, h2 {
    text-transform: uppercase;
    text-align: center;
  }
  h1 {
    font-size: 1.75rem;
  }
  main {
    padding: 1rem;
    h1, h2, p {
      color: ${colors.dark};
    }
    button {
      display: block;
      width: 100%;
      /* margin: 0; */
    }
  }
`;

export const AppContainer = styled.div`
  padding-top: var(--header-height);
  h1 {
    font-size: 1.5rem;
    text-align: center;
  }
  h2 {
    font-size: 1.25rem;
  }
  a {
    text-decoration: none;
  }
  main {
    padding: 1rem;

    &.contain {
      max-width: 560px;
      margin: auto;
    }

    h1, h2, h3, h4, p {
      color: ${colors.dark};
    }
    button.block {
      display: block;
      /* width: 100%; */
      /* margin: 0; */
    }
    form {
      & > label, & > button {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
`;
