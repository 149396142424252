import React from "react";
import { PlanCardContainer } from "./plansStyles";
import { ButtonPrimary } from "../../styles/buttons";

const PlanCard = ({ plan }) => {

  const [priceInteger, priceCents] = plan.price.split(".");

  return (
    <PlanCardContainer>
      <h2>{plan.name}</h2>
      <ul>
        {plan.advantages.map(advantage => (
          <li key={`plan-${plan.id}-advantage-${advantage.id}`}>
            {advantage.title}
          </li>
        ))}
      </ul>
      <p className="price">
          <span className="smaller">R$</span>
          <span>{priceInteger}</span>
          <span className="smaller column">
            <span>,{priceCents}</span>
            <span>/mês</span>
          </span>
      </p>
      <ButtonPrimary>Começar</ButtonPrimary>
    </PlanCardContainer>
  )
}

export default PlanCard;
