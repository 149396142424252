import React, { useState } from "react";
import FormWizard from "../FormWizard/FormWizard";
import { ButtonPrimary, LinkButton } from "../../styles/buttons";
import { useParams, useHistory } from "react-router-dom";
import { Label, Input, Alert } from "../../styles/forms";
import { Grid } from "../../styles/grid";
import { useSelector } from "react-redux";
import { updateProcess } from "../../providers/processProvider";
import { allRequiredFull } from "../../utils/validation";
import ModalConfirm from "../ModalConfirm/ModalConfirm";


const initialPayload = {
  "street": "",
  "number": "",
  "district": "",
  "city": "",
  "state": "",
  "postcode": "",
  "complement": ""
}

const TaxAddress = props => {

  const { step, process } = useParams();
  const history = useHistory();
  const user = useSelector(state => state.auth.user);

  const [confirmOpen, setConfirmOpen] = useState(false);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(initialPayload);

  const toggleConfirm = (e) => {
    e.preventDefault();
    setConfirmOpen(!confirmOpen);
  }

  const onChangeHandler = e => {
    setForm({...form, [e.target.name]: e.target.value});
  }

  const next = () => {
    const nextStep = Number(step) + 1;
    
    history.push(`/app/adicionar/abrir/${nextStep}/${process}`);
  }

  const send = async e => {
    e.preventDefault();
    if(loading) return;

    const required = {...form}
    delete required.complement;

    if(!allRequiredFull(required)) {
      setError("Complete todos os campos obrigatórios para avançar");
      return;
    }

    setLoading(true);
    setError("");

    //getProcess(2, user.token);

    const payload = {
      step: 4,
      entity_data: {
        id: process,
        company_data: {
          address_data: form
        }
      }
    }

    const result = await updateProcess(payload, process, user.token);
    console.log(result);
    setLoading(false);
    
    if(result.error) {
      setError(result.errorMessage);
      return;
    }

    next()
  }

  return (
    <>
    <FormWizard>
      <div className="content">

        <div className="content-header">
          <h2>Endereço Fiscal</h2>
          <p>Nos informe o endereço fiscal de sua empresa</p>
        </div>

        <Grid className="content-body">

          {/* <p>Não possui endereço fiscal? {" "}
            <LinkButton inline onClick={toggleConfirm}>
              Clique aqui e solicite agora!
            </LinkButton>
          </p> */}

          <Label className="col-9">
            <span>Rua</span>
            <Input name="street" onChange={onChangeHandler} placeholder="Rua*" />
          </Label>
          <Label className="col-3">
            <span>Número</span>
            <Input name="number" onChange={onChangeHandler} placeholder="Número*" />
          </Label>
          <Label>
            <span>Complemento</span>
            <Input name="complement" onChange={onChangeHandler} placeholder="Complemento" />
          </Label>
          <Label className="col-6">
            <span>CEP</span>
            <Input name="postcode" onChange={onChangeHandler} placeholder="CEP*" />
          </Label>
          <Label className="col-6">
            <span>Bairro</span>
            <Input name="district" onChange={onChangeHandler} placeholder="Bairro*" />
          </Label>
          <Label className="col-6">
            <span>Cidade</span>
            <Input name="city" onChange={onChangeHandler} placeholder="Cidade*" />
          </Label>
          <Label className="col-6">
            <span>Estado</span>
            <Input name="state" onChange={onChangeHandler} placeholder="Estado*" />
          </Label>
          
        </Grid>
        
      </div>

      <Alert show={error}>{error}</Alert>

      <ButtonPrimary app className="block" onClick={send}>
        Avançar
      </ButtonPrimary>
    </FormWizard>
    <ModalConfirm 
        show={confirmOpen}
        toggle={toggleConfirm}
        confirmHandler={toggleConfirm}
      >
        Confirmar a solicitação de abertura de endereço fiscal?
      </ModalConfirm>
    </>
  )
}
export default TaxAddress;
