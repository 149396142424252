import styled from "styled-components";
import { colors } from "../../styles/constants";
import { ImageButton } from "../../styles/buttons";

import buttonIcon from "../../assets/chat-button-white.png";
import chatHeaderIcon from "../../assets/chat-button-blue.png";
import { pseudoElementImage } from "../../styles/mixins";

export const ChatbotContainer = styled.div`
  position: fixed;
  bottom: 4rem;
  right: 1rem;

  .react-chatbot-kit-chat-container {
    /* left: -1rem;
    top: -3rem; */
    box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.5);
    
    .react-chatbot-kit-chat-inner-container {
      border-radius: 0 0 5px 5px;
      height: 450px;
    }
    .react-chatbot-kit-chat-header {
      display: none;
    }
  }

  .react-chatbot-kit-chat-bot-message {
    margin-left: 8px;
    background-color: ${colors.secondary};
    color: #000;
    .react-chatbot-kit-chat-bot-message-arrow {
      border-right: 8px solid ${colors.secondary};
    }
  }

  .options-container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .option-button {
    padding: 0.5rem;
    border-radius: 25px;
    background: transparent;
    border: 2px solid ${colors.primary};
    color: ${colors.primary};
    margin: 3px;
    font-size: 0.9rem;
    cursor: pointer;
  }

  .react-chatbot-kit-chat-input-container {
    padding: 0.5rem;
    background: ${colors.gray};
    .react-chatbot-kit-chat-input {
      border-radius: 42px;
      width: calc(100% - 42px - 0.5rem);
      ::placeholder {
        color: transparent;
      }
      :focus {
        outline: none;
      }
    }

    .react-chatbot-kit-chat-btn-send {
      width: 42px;
      height: 42px;
      border-radius: 21px;
      margin-left: 0.5rem;
      background-color: ${colors.secondary};
    }
  }
`;

export const ChatbotHeader = styled.div`
  background-color: ${colors.primary};
  color: ${colors.light};
  border-radius: 5px 5px 0 0;
  padding: 0.6rem 0.9rem;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  ::before {
    ${pseudoElementImage}
    background-color: ${colors.light};
    background-image: ${`url(${chatHeaderIcon})`};
    background-size: 60%;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 0.9rem;
    margin-right: 0.9rem;
  }
`;

export const ChatbotButton = styled(ImageButton)`
  position: absolute;
  bottom: -2.75rem;
  right: 0;
  padding: 1.5rem;
  margin: 0;
  background-color: ${colors.primary};
  border-radius: 1.5rem;
  box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.5);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  background-image: ${`url(${buttonIcon})`};
`;
