import axios from "./config";

export const createIndication = async (userId, token) => {

  const config = {
    headers: { Authorization: `Token ${token}` }
  };

  try {
    const result = await axios.post("/indication/", {owner:userId}, config);
    //console.log(result);
    return result.data;
  } catch (error) {
    console.log(error.response);
    return {error: true, errorMessage: "Ocorreu um problema e não foi possível gerar um link de indicação"}
  }
};

export const getIndications = async (userId, token, page=1) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(
      `/indication/?owner=${userId}&page=${page}`,
      config
    );
    //console.log(result);
    return result.data;
  } catch (error) {
    console.log(error.response);
    return { error: error.response };
  }
}
