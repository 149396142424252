import React from "react";
import Modal from "../Modal/Modal";
import { ConfirmContainer, ConfirmText, ButtonContainer } from "./modalConfirmStyles";
import { ButtonSubmit, ButtonPrimary } from "../../styles/buttons";

const ModalConfirm = props => {
  return (
    <Modal toggle={props.toggle} isOpen={props.show}>
      <ConfirmContainer>
        <ConfirmText>
          {props.children}
        </ConfirmText>
        <ButtonContainer>
          <ButtonPrimary app onClick={props.confirmHandler}>Confirmar</ButtonPrimary>
          <ButtonSubmit onClick={props.toggle}>Cancelar</ButtonSubmit>
        </ButtonContainer>
      </ConfirmContainer>
    </Modal>
  )
}

export default ModalConfirm;
