import styled from "styled-components";
import { colors } from "../../styles/constants";

export const AddTransactionsContainer = styled.div`
  background-color: ${colors.light};
  padding: 1.5rem;
  border-radius: 4px;
  width: 316px;

  form {
    & > * {
      margin: 0 0 1rem 0;
    }
    button.submit-btn {
      display: block;
      width: 100%;
      margin-bottom:0;
    }
  }
`;
