import React, { useState } from "react";
import { SignInContainer } from "../SignInScreen/signInStyles";
import { Alert, Label, Input } from "../../styles/forms";

import logo from "../../assets/logo-name.png";
import { ButtonPrimary } from "../../styles/buttons";
import { Link, Redirect, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { allRequiredFull, isEmailValid, passwordMatch } from "../../utils/validation";
import { register } from "../../providers/authProvider";
import { Grid } from "../../styles/grid";

const initialPayload = {
  first_name: "",
  last_name: "",
  email: "",
  taxpayer: "",
  phone: "",
  password: "",
}

const initialAddressPayload = {
  street: "",
  number: "",
  district: "",
  city: "",
  state: "",
  postcode: "",
  complement: "",
}

const SingUpScreen = props => {

  const dispatch = useDispatch();
  const isLogged = useSelector((state) => state.auth.isLogged);

  const [payload, setPayload] = useState(initialPayload);
  const [payloadAddress, setPayloadAddress] = useState(initialAddressPayload);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const params = useParams();

  const onChangeHandler = e => {
    setPayload({...payload, [e.target.name]: e.target.value});
  }

  const onChangeAddressHandler = e => {
    setPayloadAddress({...payloadAddress, [e.target.name]: e.target.value});
  }

  const isValid = () => {
    if(!allRequiredFull(payload)) {
      setValidationMessage("É necessário preencher todos os campos de informações da conta");
      return false;
    }

    const required = {...payloadAddress}
    delete required.complement;
    if(!allRequiredFull(required)) {
      setError("Complete todos os campos de endereço obrigatórios para avançar");
      return;
    }

    if(!isEmailValid(payload.email)) {
      setValidationMessage("Insira um e-mail válido");
      return false
    }
    if(!passwordMatch(payload.password, passwordConfirmation)) {
      setValidationMessage("Erro ao confirmar sua senha. Verifique se escreveu corretamente");
      return false;
    }
    return true;
  }

  const send = async e => {
    e.preventDefault();
    if(loading) return;
    setLoading(true);
    setError("");
    setValidationMessage("");

    if(!isValid()) {
      setLoading(false);
      return;
    }

    let data = {
      ...payload,
      address_data: { ...payloadAddress },
    }

    if(params.token) {
      data.token = params.token;
    }

    const result = await dispatch(register({
      ...data
    }));
    setLoading(false);
    if(result.error) {
      setError("Não foi possível realizar seu cadastro");
      return;
    }
  }

  if(isLogged) {
    return <Redirect to="/app/inicio/empresas" />;
  }
  
  return (
    <SignInContainer>
      <img className="logo" src={logo} alt="Logo Contabilizar" />
      <h1>Cadastro</h1>
      <p>Crie sua conta <strong>Contabilizar</strong>.</p>

      <form>
        <section>
          {/* <h2>Informações da conta</h2> */}
          <Label>
            <span>Nome</span>
            <Input name="first_name" onChange={onChangeHandler} placeholder="Nome*" />
          </Label>
          <Label>
            <span>Sobrenome</span>
            <Input name="last_name" onChange={onChangeHandler} placeholder="Sobrenome*" />
          </Label>
          <Label>
            <span>E-mail</span>
            <Input name="email" onChange={onChangeHandler} placeholder="E-mail*" />
          </Label>
          <Label>
            <span>CPF</span>
            <Input name="taxpayer" onChange={onChangeHandler} placeholder="CPF*" />
          </Label>
          <Label>
            <span>Telefone</span>
            <Input name="phone" onChange={onChangeHandler} placeholder="Telefone*" />
          </Label>
          <Label>
            <span>Senha</span>
            <Input name="password" onChange={onChangeHandler} placeholder="Senha*" type="password" />
          </Label>
          <Label>
            <span>Confirmar Senha</span>
            <Input placeholder="Confirmar Senha*" type="password" 
              onChange={e => setPasswordConfirmation(e.target.value)} 
            />
          </Label>
        </section>

        <Grid>
          <h2>Endereço</h2>
          <Label className="col-9">
            <span>Rua</span>
            <Input name="street" onChange={onChangeAddressHandler} placeholder="Rua*" />
          </Label>
          <Label className="col-3">
            <span>Número</span>
            <Input name="number" onChange={onChangeAddressHandler} placeholder="Número*" />
          </Label>
          <Label>
            <span>Complemento</span>
            <Input name="complement" onChange={onChangeAddressHandler} placeholder="Complemento" />
          </Label>
          <Label className="col-6">
            <span>CEP</span>
            <Input name="postcode" onChange={onChangeAddressHandler} placeholder="CEP*" />
          </Label>
          <Label className="col-6">
            <span>Bairro</span>
            <Input name="district" onChange={onChangeAddressHandler} placeholder="Bairro*" />
          </Label>
          <Label className="col-6">
            <span>Cidade</span>
            <Input name="city" onChange={onChangeAddressHandler} placeholder="Cidade*" />
          </Label>
          <Label className="col-6">
            <span>Estado</span>
            <Input name="state" onChange={onChangeAddressHandler} placeholder="Estado*" />
          </Label>
          
        </Grid>

        <Alert show={validationMessage}>{validationMessage}</Alert>
        <Alert show={error}>{error}</Alert>

        <ButtonPrimary onClick={send}>
          {loading ? "Carregando..." : "Cadastrar"}
        </ButtonPrimary>
      </form>

      <p className="register-link">Já possui conta? <Link to="/login">Clique aqui!</Link></p>
    </SignInContainer>
  )
}

export default SingUpScreen;
