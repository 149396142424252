import axios from "./config";

export const generatePayment = async (processId, token) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };

  const payload = {
    process: processId,
    type: "CADASTRO",
  }

  try {
    const result = await axios.post("/process/generate-boleto/", payload, config);
    //console.log(result);
    return result.data;
  } catch (error) {
    console.log(error.response);
    return {error: true, errorMessage: "Ocorreu um problema e não foi possível gerar um boleto"}
  }
}

export const generateMonthlyPayment = async (processId, paymentId, token) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };

  const payload = {
    process: processId,
    type: "MENSALIDADE",
    payment_id: paymentId
  }

  try {
    const result = await axios.post("/process/generate-boleto/", payload, config);
    //console.log(result);
    return result.data;
  } catch (error) {
    console.log(error.response);
    return {error: true, errorMessage: "Ocorreu um problema e não foi possível gerar um boleto"}
  }
}

export const getPaymentHistory = async (process, token, page=1) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(
      `/payment-history/?process=${process}&page=${page}`,
      config
    );
    //console.log(result);
    return result.data;
  } catch (error) {
    console.log(error.response);
    return { error: error.response };
  }
}
