import styled from "styled-components";

export const ProfileContainer = styled.div`

  form {
    max-width: 560px;
    margin: auto;
    .grid-container {
      & > * {
        margin-bottom: 1rem;
      }
    }
  }

  .link {
    margin: auto;
  }
`;
