import styled from "styled-components";
import { colors } from "../../styles/constants";

export const ConfirmContainer = styled.div`
  background-color: ${colors.light};
  padding: 1.5rem;
  border-radius: 4px;
  width: 316px;
`;

export const ConfirmText = styled.p`
  color: ${colors.primary};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  button {
    margin: 0;
  }
`;
