import styled from "styled-components";
import { colors } from "../../styles/constants";
import { CardContainer } from "../../components/Card/cardStyles";

import logo from "../../assets/logo-symbol.png";
import { pseudoElementImage } from "../../styles/mixins";

export const PlansContainer = styled.main`
  padding: 1rem;
  @media (min-width: 1023px) {
    padding: 2rem 4rem;
  }

  h1 {
    font-size: 1.5rem;
  }
  p {
    text-align: center;
  }
`;

export const PlansGrid = styled.div`
  max-width: 1950px;
  margin: auto;
  padding: 1rem;
  @media (min-width: 960px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1.5rem;
    justify-items: center;
    align-items: flex-start;
  }
`;

export const PlanCardContainer = styled(CardContainer)`
  margin: 2rem auto;
  padding: 1.5rem;
  max-width: 560px;

  h2 {
    margin: auto;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    width: fit-content;
    /* background-color: pink; */
    background-image: ${`linear-gradient(90deg, ${colors.secondary} 0px 0.5em, rgba(0,0,0,0) 0.5em 100%)`};
    background-image: ${`-webkit-linear-gradient(90deg, ${colors.secondary} 0px 0.5em, rgba(0,0,0,0) 0.5em 100%)`};
    background-image: ${`-moz-linear-gradient(90deg, ${colors.secondary} 0px 0.5em, rgba(0,0,0,0) 0.5em 100%)`};
  }

  button {
    font-weight: 600;
    margin: auto;
  }

  p {
    text-align: initial;
    margin: 1.75rem auto;
  }

  .price {
    font-size: 1.75rem;
    font-weight: 800;
    text-align: center;
    margin: 1.75rem auto;
    display: flex;
    justify-content: center;
    line-height: 1;
    & > * {
      
    }
    span.smaller {
      font-size: 0.875rem;
      position: relative;
      margin: 0 2px;
    }
    span.column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  ul {
    margin: 1.75rem 0;
    color: ${colors.dark};
    font-weight: 700;
    li {
      margin: 1rem 0;
      display: flex;
      align-items: center;
      &::before {
        ${pseudoElementImage}
        background-image: ${`url(${logo})`};
        /* background-size: 60%; */
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.75rem;
      }
    }
  }

`;
