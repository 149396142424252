import styled from "styled-components";
import { colors } from "../../styles/constants";

export const SignInContainer = styled.main`

  max-width: 520px;
  margin: auto;

  .logo {
    margin: auto;
    margin-bottom: 3rem;
    display: block;
  }

  p {
    text-align: center;
  }

  form {
    margin: 2rem 0;

    h2 {
      text-align: left;
      font-size: 1.175rem;
    }

    button {
      margin: 0;
    }

    & > label, & > button {
      margin: 1rem 0;
    }

    & > div label, & > div button {
      margin: 0.5rem 0;
    }

    & > section label, & > section button {
      margin: 1rem 0;
    }

    a {
      font-size: 0.9rem;
      color: ${colors.grayDark};
    }

    .recover-password {
      position: relative;
      top: -0.5rem;
    }
    
  }

  .register-link {
    font-size: 0.9rem;
    color: ${colors.grayDark};
  }

`
