import React, { useState } from "react";
import FormWizard from "../FormWizard/FormWizard";
import { ButtonPrimary } from "../../styles/buttons";
import { useParams, useHistory } from "react-router-dom";
import { Label, Input, Alert } from "../../styles/forms";
import { updateProcess } from "../../providers/processProvider";
import { useSelector } from "react-redux";

const ValidateCompanyRegister = props => {

  const user = useSelector(state => state.auth.user);
  const params = useParams();
  const history = useHistory();
  const [registerNumber, setRegisterNumber] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const next = e => {
    const step = Number(params.step);
    
    history.push(`/app/adicionar/migrar/${step + 1}/${params.process}`);
  }

  const validateRegister = async e => {
    e.preventDefault();

    // const result = await fetch(`https://www.receitaws.com.br/v1/cnpj/27865757000102`)
    // console.log(result);

    if(loading) return;

    setLoading(true);
    setError("");

    const data = {
      step: 3,
      entity_data: {
        id: params.process,
        cpf_cnpj: registerNumber,
      }
    }

    const result = await updateProcess(data, params.process, user.token);
    console.log(result);
    setLoading(false);
    
    if(result.error) {
      setError(result.errorMessage);
      return;
    }
    

    next();
  }

  return (
    <FormWizard>
      <div className="content">

        <div className="content-header">
          <h2>Validar CNPJ</h2>
          <p>Informe o CNPJ da empresa que pretende migrar</p>
        </div>

        <div className="content-body">
          <Label>
            <span>CNPJ</span>
            <Input onChange={e => setRegisterNumber(e.target.value)} placeholder="CNPJ" />
          </Label>
        </div>

      </div>

      <Alert show={error}>{error}</Alert>

      <ButtonPrimary disabled={!registerNumber} app className="block" onClick={validateRegister}>
        {loading ? "Carregando..." : "Avançar"}
      </ButtonPrimary>
    </FormWizard>
  )
}
export default ValidateCompanyRegister;
