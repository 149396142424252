import React, { useState } from "react";
import { useSelector } from "react-redux";
import { addTransaction } from "../../providers/processProvider";
import { ButtonPrimary, ButtonSubmit } from "../../styles/buttons";
import { Alert, AlertSucess, Input, Label, LabelDraggable } from "../../styles/forms";
import Modal from "../Modal/Modal";
import { AddTransactionsContainer } from "./addTransactionsStyles";

const AddTransactions = props => {

  const user = useSelector(state => state.auth.user);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [file, setFile] = useState({name:""});

  const onFileChange = e => {
    const file = e.target.files[0];
    if(!file) {
      return;
    }
    setFile(file);
  }

  const onDropFile = e => {
    e.preventDefault();

    if (e.dataTransfer.items) {
      
        if (e.dataTransfer.items[0].kind === 'file') {
          const file = e.dataTransfer.items[0].getAsFile();
          console.log(file);
          setFile(file);
        }
      
    } else {
        console.log(e.dataTransfer.files[0]);
        setFile(e.dataTransfer.files[0]);
    }
  }

  const send = async e => {
    e.preventDefault();
    if(loading) return;

    setLoading(true);
    setError("");
    setSuccess("");

    const payload = {
      name: name,
      process: props.process.id,
      document: file
    }

    const result = await addTransaction(payload, user.token);
    console.log(result);
    setLoading(false);
    
    if(result.error) {
      setError(result.errorMessage);
      return;
    }

    setSuccess("Movimentação adicionada com sucesso!");
    //setTimeout(() => setSuccess(""), 3000);

  }

  return (
    <Modal toggle={props.toggle} isOpen={props.show}>
      <AddTransactionsContainer>
        <form>
          <h4>Anexar Movimentações</h4>
          <Label>
            <span>Descrição</span>
            <Input value={name} onChange={e => setName(e.target.value)} placeholder="Descrição" />
          </Label>
          <LabelDraggable
            onDragEnter={() => console.log("drag enter")}
            onDragLeave={() => console.log("drag leave")}
            onDragOver={e => e.preventDefault()}
            onDrop={onDropFile}
          >
            {file.name 
              ? file.name 
              : "Arraste um arquivo ou clique para adicionar sua movimentação"
            }
            <input type="file" onChange={onFileChange} />
          </LabelDraggable>

          <Alert show={error}>{error}</Alert>
          <AlertSucess show={success}>{success}</AlertSucess>

          <ButtonPrimary className="submit-btn" app onClick={send}>
            {loading ? "Carregando..." : "Enviar"}
          </ButtonPrimary>
        </form>

      </AddTransactionsContainer>
    </Modal>
  )
}

export default AddTransactions;
