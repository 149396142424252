import React from 'react';
import Footer from '../components/Footer/Footer';
import { LandingPage } from '../styles/landingPageStyles';
import Header from '../components/Header/Header';
import Chatbot from '../components/Chatbot/Chatbot';

const LandingPageLayout = ({children, ...rest}) => {
  return (
    <LandingPage {...rest}>
      <Header />
      {children}
      <Chatbot />
      <Footer />
    </LandingPage>
  );
}

export default LandingPageLayout;
