import React, { useEffect, useState } from "react";
import { ButtonPrimary, ButtonSubmit, LinkButton } from "../../styles/buttons";
import { IndicationsContainer } from "./indicationsStyles";
//import AddModal from "./AddModal";
import { useSelector } from "react-redux";
import { createIndication, getIndications } from "../../providers/indicationsProvider";
import { convertDate } from "../../utils/dates";
import LinkModal from "./LinkModal";

const IndicationsScreen = props => {

  const user = useSelector(state => state.auth.user);
  //const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [indications, setIndications] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [errorList, setErrorList] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(false);
  const [link, setLink] = useState("");

  const generateLink = async () => {
    if(loading) return;
    setLoading(true);
    setError("");

    const result = await createIndication(user.id, user.token);
    console.log(result);

    setLoading(false);

    if(result.error) {
      setError(result.errorMessage);
      return
    }

    //reload indications
    setIndications([result, ...indications]);
  }

  useEffect(() => {
    //console.log("loading", loadingList);
    console.log("limit", limit);
    if(limit) return;
    //if(loadingList) return;
    const getData = async () => {
      //setLoadingList(true);
      setErrorList("");

      const result = await getIndications(user.id, user.token, page);
      console.log(result);
      
      if(result.error) {
        setErrorList(result.error);
        //setLoadingList(false);
        return;
      }

      if(!result.next) {
        setLimit(true);
      }

      setIndications(list => [...list, ...result.results]);
      //setLoadingList(false);

    }

    getData();
  }, [user.id, user.token, limit, page])

  const nextPage = () => {
    if(limit) return;
    setPage(page + 1);
  }

  return (
    <>
    <IndicationsContainer>
      <h1>Minhas Indicações</h1>
      <ButtonPrimary app className="block" onClick={generateLink}>Adicionar indicado</ButtonPrimary>
      {indications.length > 0 ? (
        <section>
          <h2>Seus indicados</h2>
          <table>
            <thead>
              <tr>
                <th>Gerado em</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {indications.map(item => (
                <React.Fragment key={`indication-${item.id}`}>
                  <tr>
                    <td>{convertDate(item.created_at)}</td>
                    <td>{item.redeemed ? "Utilizado" : "Aguardando"}</td>
                    <td>
                      {/* <span className="to-copy" id={`to-copy-${item.id}`}>{item.token}</span> */}
                      {/* <input className="to-copy" id={`to-copy-${item.id}`} value={item.token} onChange={() => {}} /> */}
                      <LinkButton onClick={() => setLink(item.token)}>Ver link</LinkButton>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
          {!limit ? (
            <ButtonSubmit className="pagination-button" onClick={nextPage}>
              Ver mais
            </ButtonSubmit>
          ) : null}
        </section>
      ) : null}
    </IndicationsContainer>
    {/* <AddModal 
      show={showModal}
      toggle={() => setShowModal(!showModal)}
    /> */}
    <LinkModal
      link={link}
      show={link !== ""}
      toggle={() => setLink("")}
    />
    </>
  )
}

export default IndicationsScreen;
