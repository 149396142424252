import styled from "styled-components";

export const ContactContainer = styled.main`
  padding-top: calc(60px + 1rem);
  padding-bottom: calc((60px + 1rem) / 2);
  max-width: 620px;
  padding: 1rem;
  margin: auto;

  h1 {
    text-transform: uppercase;
    text-align: center;
    font-size: 1.75rem;
  }

`;
