import React from "react";
import Options from "./Options";

const InitialOptions = (props) => {
  const options = [
    { text: "Planos", handler: props.actionProvider.handlePlans, id: 1 },
    { text: "Serviços", handler: () => {}, id: 2 },
    { text: "Métodos de pagamento", handler: props.actionProvider.handlePayments, id: 3 },
  ];

  return <Options {...props} options={options} />;
};

export default InitialOptions;
