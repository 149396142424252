import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";

import InitialOptions from "./Options/InitialOptions";
//import ChatLink from "./Options/ChatLink";
import PaymentOptions from "./Options/PaymentOptions";

const config = {
  initialMessages: [
    createChatBotMessage(`Olá! O que deseja saber sobre o Contabilizar?`, {
      widget: "initialOptions"
    })
  ],
  widgets: [
    {
      widgetName: "initialOptions",
    	widgetFunc: (props) => <InitialOptions {...props} />,
    },
    // {
    //   widgetName: "link-planos",
    //   widgetFunc: (props) => <ChatLink {...props} />,
    //   props: {
    //     text: "Ver planos",
    //     url: "/planos",
    //   }
    // },
    {
      widgetName: "paymentOptions",
    	widgetFunc: (props) => <PaymentOptions {...props} />,
    },
  ],
  customComponents: {
    // Replaces the default bot avatar
    botAvatar: (props) => null,
    // Replaces the default bot chat message container
    //botChatMessage: (props) => <CustomChatMessage {...props} />,
    // Replaces the default user icon
    userAvatar: (props) => null,
    // Replaces the default user chat message
    //userChatMessage: (props) => <MyUserChatMessage {...props} />
  }
}

export default config;
