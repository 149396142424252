import styled from "styled-components";
import { colors } from "../../styles/constants";

export const ProcessUpdateContainer = styled.div`
  width: 100%;
  border: 1px solid ${colors.primary};
  border-radius: 3px;
  padding: 1rem 0.75rem;
  margin-bottom: 1rem;

  h3 {
    font-size: 1rem;
    margin-top: 0;
  }

  & > * {
    margin-bottom: 0.75rem;
  }

  a {
    margin: auto;
    display: block;
    button {
      padding: 0;
      margin: auto;
    }
  }
`;
