import React from "react";
import { Label, Input } from "../../styles/forms";
import { SocialAuthContainer } from "./styles";

const SocialAuth = props => {

  return (
    <SocialAuthContainer>

      <Label>
        <span>Nome completo</span>
        <Input name="name" onChange={props.onChangeHandler} placeholder="Nome completo" />
      </Label>

      <h3>Acesso ao e-social</h3>
      <p>Informe suas credenciais de acesso abaixo</p>
      <Label>
        <span>Código de acesso</span>
        <Input name="esocial_access_code" onChange={props.onChangeHandler} placeholder="Código de acesso" />
      </Label>
      <Label>
        <span>Senha</span>
        <Input type="password" name="esocial_password" onChange={props.onChangeHandler} placeholder="Senha" />
      </Label>

    </SocialAuthContainer>
  )
}
export default SocialAuth;
