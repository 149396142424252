import styled from "styled-components";
import { colors } from "../../styles/constants";
import { pseudoElementImage } from "../../styles/mixins";

import check from "../../assets/check.png";

const Section = styled.section`
  padding: 1rem;
  padding-top: calc(60px + 1rem);
  padding-bottom: calc(60px + 1rem);
  background-color: ${colors.light};
  @media (min-width: 1023px) {
    padding-right: 4rem;
    padding-left: 4rem;
  }
`;

export const Container = styled.div`
  max-width: 560px;
  margin: auto;
`;

export const MainSection = styled(Section)`
  min-height: 100vh;
  color: ${colors.primary};
  padding: 60px 0;

  .main-title {
    font-size: 1.75rem;
    margin: 1rem auto;
    text-transform: none;
  }

  .content {
    grid-area: content;
    padding: 0 1rem;
    max-width: 480px;
    margin: auto;
  }

  p {
    text-align: center;
  }

  ul li {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    &::before {
      ${pseudoElementImage}
      background-image: ${`url(${check})`};
      width: 1rem;
      height: 1rem;
      margin-right: 1rem;
    }
  }

  .cta-container {
    grid-area: cta;
    display: flex;
    flex-direction: column;
    max-width: 280px;
    margin: auto;
    & > * {
      margin-top: 1rem;
    }
    a button {
      margin: 0;
      width: 100%;
    }
  }

  @media (min-width: 580px) {
    padding-bottom: 0;
    display: grid;
    grid-template-areas: 
      "banner content"
      "cta cta";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 5fr 2fr;
    align-items: stretch;
    justify-items: stretch;
  }

  @media (min-width: 920px) {
    display: grid;
    grid-template-areas: 
      "banner content"
      "banner cta"
      "banner .";
    grid-template-rows: 3fr 1fr 1fr;
  }

  @media (min-width: 1023px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

export const Banner = styled.figure`
  grid-area: banner;
  margin:0;
  max-width: 100vw;
  img {
    width: 100%;
    object-fit: scale-down;
  }
  @media (min-width: 580px) {
    img {
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const ContactSection = styled(Section)`
  background-color: ${colors.primary};
  color: ${colors.light};
  .logo {
    display: block;
    margin: calc(60px + 1rem) auto 0 auto;
  }
  form {
    button:last-child {
      margin: 1.5rem 0;
      width: 100%;
    }
  }
`;

export const AdvantagesSection = styled(Section)`
  color: ${colors.primary};
`;

export const AdvantagesGrid = styled.div`
  max-width: 1950px;
  margin: auto;
  & > * {
    margin: 2.5rem auto;
    max-width: 560px;
  }
  @media (min-width: 960px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1.5rem;
    justify-items: center;
    align-items: center;
  }
`;

export const AdvantageHeader = styled.div`
  height: 144px;
  width: 100%;
  background-color: ${colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    object-fit: contain;
    height: 60%;
  }
`;

export const AdvantageContent = styled.div`
  min-height: 144px;
  width: 100%;
  padding: 1rem;
  text-align: center;
  h3 {
    margin-bottom: 0.35rem;
  }
  p {
    font-size: 0.9rem;
  }
  img {
    display: block;
    margin: auto;
  }
`;
