import styled from "styled-components";
import { colors } from "../../styles/constants";

export const PaymentsContainer = styled.div`
  background-color: ${colors.light};
  padding: 1.5rem 0;
  border-radius: 4px;
  min-width: 284px;
  max-width: calc(100vw - 16px);

  & > h4 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    text-align: center;
  }

  & > button {
    margin: 0.5rem auto 0 auto;
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    min-width: auto;
  }

  .report-item-list {
    overflow-y: auto;
    max-height: calc(80vh - 2rem);
    padding: 0 1.5rem;
  }

  p.center {
    text-align: center;
  }

`;

export const PaymentItem = styled.div`
  padding: 1rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.gray};
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin: 0.915rem 0;
    }
  }

  p.paid-badge {
    padding: 0.5rem 0.75rem;
    background-color: ${colors.primary};
    border-radius: 1rem;
    color: ${colors.light};
    opacity: 0.7;
  }

  .document-link {
    margin-right: 1.5rem;
    button {
      padding: 0;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.gray};
  }
`;
