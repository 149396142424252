import React from "react";

const Options = (props) => {
  // const options = [
  //   { text: "Planos", handler: props.actionProvider.handlePlans, id: 1 },
  //   { text: "Serviços", handler: () => {}, id: 2 },
  //   { text: "Métodos de pagamento", handler: () => {}, id: 3 },
  // ];

  const optionsMarkup = props.options.map((option) => (
    <button
      className="option-button"
      key={option.id}
      onClick={option.handler}
    >
      {option.text}
    </button>
  ));

  return <div className="options-container">{optionsMarkup}</div>;
};

export default Options;
