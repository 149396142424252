import styled from "styled-components";
import { colors } from "../../styles/constants";

const FormWizard = styled.form`
  
  .content {
    border: 1px solid ${colors.dark};
    padding: 1.5rem;
    min-height: 50vh;
    max-width: 560px;
    margin: auto;

    h3 {
      font-size: 1rem;
    }

    .content-header {
      margin-bottom: 2rem;
      h2, p {
        text-align: center;
        margin-top: 0;
      }
    }

    p.center {
      text-align: center;
    }

    .content-body {

      /* @media (min-width: 560px) {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        column-gap: 1rem;

        & > * {
          grid-column: 1 / span 12;
        }

        & > *.col-9 {
          grid-column: auto / span 9;
        }

        & > *.col-6 {
          grid-column: auto / span 6;
        }

        & > *.col-3 {
          grid-column: auto / span 3;
        }
      } */

      & > *:not(:last-child) {
        margin-bottom: 1rem;
      }

      & > .scroll {
        max-height: 45vh;
        overflow-y: scroll;
        padding: 0 0.5rem;
      }
    }
  }

  button {
    margin: auto;
  }

`

export const PartnerList = styled.ul`
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      margin: unset;
    }
  }
`

export default FormWizard;
