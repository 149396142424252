import styled from "styled-components";
import { colors } from "../../styles/constants";

export const MessageContainer = styled.div`
  background-color: ${colors.light};
  padding: 1rem 2rem;
  border-radius: 4px;
`;

export const MessageTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: ${colors.primary};
`;

export const MessageText = styled.p`
  color: ${colors.primary};
`;
