import styled from "styled-components";
import { colors } from "./constants";

export const Form = styled.form`
  margin: 3rem 0;
  & > * {
    margin: 1.5rem 0;
  }
  button:last-child {
    margin: 2rem auto;
  }
`;

export const Label = styled.label`
  display: block;
  span {
    visibility: hidden;
    font-size: 0;
  }
`;

export const LabelFile = styled.label`
  display: block;
  border: 2px solid ${colors.secondary};
  color: ${({ color = colors.primary }) => `${color}`};
  border-radius: 3px;
  min-width: 130px;
  padding: 0.75rem 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  max-width: 50%;
  margin-left: auto;

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`;

export const LabelDraggable = styled.label`
  display: block;
  border: 2px solid ${colors.secondary};
  border-style: dashed;
  color: ${({ color = colors.primary }) => `${color}`};
  border-radius: 3px;
  padding: 2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.9rem;

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`;

export const RadioLabel = styled.label`
  display: block;
  /* position: relative;
  padding-left: 35px;
  margin-bottom: 12px; */
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    display: flex;
    align-items: flex-end;
  }

  span::before {
    content: "";
    /* position: absolute;
    top: 0;
    left: 0; */
    height: 1.2rem;
    width: 1.2rem;
    border: 1px solid ${colors.primary};
    border-radius: 50%;
    margin-right: 0.75rem;
  }

  input:checked ~ span::before {
    /* background-color: ${colors.secondary}; */
    background-image: radial-gradient(circle, ${colors.secondary} 0%, ${colors.secondary} 47%, transparent 47%, transparent 100%);
  }

`;

export const Input = styled.input`
  margin-top: 0.275rem;
  border-radius: 3px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid ${colors.dark};
  padding: 0.75rem;
  width: 100%;
`;

export const Textarea = styled.textarea`
  margin-top: 0.275rem;
  border-radius: 3px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid ${colors.dark};
  padding: 0.75rem;
  width: 100%;
`;

export const Alert = styled.p`
  max-width: 560px;
  margin: 0 auto;
  margin-top: 1rem;
  padding: 0.5rem 0.75rem;
  width: 100%;
  font-weight: 400;
  border-radius: 3px;
  color: #d8000c;
  background-color: #ffbaba;
  display: ${({ show = false }) => show ? "block" : "none"};
`;

export const AlertSucess = styled(Alert)`
  color: white !important;
  background-color: green;
`;
