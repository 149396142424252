import React, { useState } from "react";
import { ContactSection, Container } from "./homeStyles";
import { Label, Input, Form, Alert } from "../../styles/forms";
import { ButtonPrimary } from "../../styles/buttons";

import logoWhite from "../../assets/logo-symbol-white.png";
import { sendLead } from "../../providers/userProvider";
import ModalMessage from "../../components/ModalMessage/ModalMessage";

const LeadSection = props => {
  const [error, setError] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [payload, setPayload] = useState({});

  const send = async e => {
    e.preventDefault();
    
    if(loading) return;
    setLoading(true);
    setError("");
    if(!isValid()) {
      setLoading(false);
      return;
    }

    const result = await sendLead(payload);
    setLoading(false);
    console.log(result);
    if(result.error) {
      setError(result.errorMessage);
      return;
    }
    setShowSuccessMessage(true);
    setPayload({});
  }

  const isValid = () => {
    const {
      first_name,
      last_name,
      email,
      phone
    } = payload;
    
    setValidationMessage("")
    
    if(!first_name) {
      setValidationMessage("É necessário enviar seu nome");
      return false;
    }
    if(!last_name) {
      setValidationMessage("É necessário enviar seu sobrenome");
      return false;
    }
    if(!email) {
      setValidationMessage("É necessário enviar seu e-mail");
      return false;
    }
    if(!phone) {
      setValidationMessage("É necessário enviar seu telefone");
      return false;
    }

    return true;
  }

  const onChangeHandler = e => {
    setPayload({...payload, [e.target.name]: e.target.value})
  }

  return (
    <ContactSection id="contato">
      <h2>Fale Conosco</h2>
      <Container>
        <p>Tire suas dúvidas entrando em contato com nosso time</p>
        <Form>
          <Label>
            <span>Nome</span>
            <Input name="first_name" placeholder="Nome" onChange={onChangeHandler} />
          </Label>
          <Label>
            <span>Sobrenome</span>
            <Input name="last_name" placeholder="Sobrenome" onChange={onChangeHandler} />
          </Label>
          <Label>
            <span>E-mail</span>
            <Input name="email" placeholder="E-mail" onChange={onChangeHandler} />
          </Label>
          <Label>
            <span>Telefone</span>
            <Input name="phone" placeholder="Telefone" onChange={onChangeHandler} />
          </Label>

          <Alert show={error}>{error}</Alert>
          <Alert show={validationMessage}>{validationMessage}</Alert>

          <ButtonPrimary onClick={send}>
            {loading ? "Carregando..." : "Enviar"}
          </ButtonPrimary>
        </Form>
      </Container>
      <img className="logo" src={logoWhite} alt="Contabilizar"/>
      <ModalMessage 
        title={"Enviado com sucesso"}
        message={"A Contabilizar entrará em contato em breve!"}
        show={showSuccessMessage}
        toggle={() => setShowSuccessMessage(!showSuccessMessage)}
      />
    </ContactSection>
  )
}

export default LeadSection;
