import React, { useState } from "react";
import FormWizard from "../FormWizard/FormWizard";
import { ButtonPrimary } from "../../styles/buttons";
import { useParams, useHistory } from "react-router-dom";
import { Label, Input, Alert } from "../../styles/forms";
import { Grid } from "../../styles/grid";
import { allRequiredFull } from "../../utils/validation";
import { useSelector } from "react-redux";
import { updateProcess } from "../../providers/processProvider";

const initialPayload = {
  name: "",
  crc: "",
  email: "",
  phone: "",
}

const formToString = formObject => {
    return Object.entries(formObject).reduce((acc, curr) => {
      const [key,value] = curr;
      let label = "";

      switch (key) {
        case "name":
          label = "Nome"
          break;
        case "crc":
          label = "CRC"
          break;
        case "email":
          label = "E-mail"
          break;
        case "phone":
          label = "Telefone"
          break;
      
        default:
          break;
      }

      return `${acc}
${label} - ${value}
`

    }, "");
}

const AccountantData = props => {

  const user = useSelector(state => state.auth.user);
  const { step, process } = useParams();
  const history = useHistory();
  const [form, setForm] = useState(initialPayload);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onChangeHandler = e => {
    setForm({...form, [e.target.name]: e.target.value});
  }

  const next = () => {
    const nextStep = Number(step) + 1;
    
    history.push(`/app/adicionar/migrar/${nextStep}/${process}`);
  }

  const send = async e => {
    e.preventDefault();
    if(loading) return;

    if(!allRequiredFull(form)) {
      setError("Complete todos os campos obrigatórios para avançar");
      return;
    }

    setLoading(true);
    setError("");


    const payload = {
      step: 6,
      entity_data: {
        id: process,
        company_data: {
          old_accountant: formToString(form)
        }
      }
    }

    const result = await updateProcess(payload, process, user.token);
    console.log(result);
    setLoading(false);
    
    if(result.error) {
      setError(result.errorMessage);
      return;
    }

    next()
  }

  return (
    <FormWizard>
      <div className="content">

        <div className="content-header">
          <h2>Dados do antigo contador</h2>
          <p>Necessitamos dos dados do seu antigo contador para realizarmos a migração</p>
        </div>

        <Grid className="content-body">

          <Label >
            <span>Nome</span>
            <Input placeholder="Nome*" name="name" onChange={onChangeHandler} />
          </Label>
          <Label>
            <span>CRC</span>
            <Input placeholder="CRC*" name="crc" onChange={onChangeHandler} />
          </Label>
          <Label >
            <span>E-mail</span>
            <Input placeholder="E-mail*" name="email" onChange={onChangeHandler} />
          </Label>
          <Label >
            <span>Telefone</span>
            <Input placeholder="Telefone*" name="phone" onChange={onChangeHandler} />
          </Label>
          
        </Grid>
        
      </div>

      <Alert show={error}>{error}</Alert>

      <ButtonPrimary app className="block" onClick={send}>
        {loading ? "Carregando..." : "Avançar"}
      </ButtonPrimary>
    </FormWizard>
  )
}
export default AccountantData;
