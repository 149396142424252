import React from "react";
import { ListContainer } from "../ProcessesListScreens/processesListStyles";
import Company from "./Company";

const CompanyList = props => {

  return (
    <ListContainer>
      <h3>Minhas empresas</h3>
      {props.list.map(item => (
        <Company key={`company-${item.id}`} company={item} />
      ))}
    </ListContainer>
  )
}

export default CompanyList;
