import React from "react";
import { Route, NavLink } from "react-router-dom";
import ProfileScreen from "../screens/ProfileScreen/ProfileScreen";
import IndicationsScreen from "../screens/IndicationsScreen/IndicationsScreen";
import { TabNavigator } from "../components/TabNavigator/TabNavigator";

const routes = [
  { path: '/app/perfil/editar', component: ProfileScreen, exact: true },
  { path: '/app/perfil/indicados', component: IndicationsScreen, exact: true },
];

const ProfileLayout = props => {

  return (
    <>
      <TabNavigator>
        <NavLink to="/app/perfil/editar">
          Editar Conta
        </NavLink>
        <NavLink to="/app/perfil/indicados">
          Indicações
        </NavLink>
      </TabNavigator>

      <main>
        {routes.map(route => (
          <Route key={route.path} {...route} />
        ))}
      </main>
    </>
  )
}

export default ProfileLayout;
