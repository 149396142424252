import React, { Component } from "react";
import { Overlay, ModalContent } from "./modalStyles";

class Modal extends Component {
  handleContentClick = e => e.stopPropagation();

  render() {
    if (!this.props.isOpen) return null;

    return (
      <Overlay onClick={this.props.toggle}>
        <ModalContent onClick={this.handleContentClick}>
          {this.props.children}
        </ModalContent>
      </Overlay>
    );
  }
}

export default Modal;
