import React, { useState } from "react";
import FormWizard from "../FormWizard/FormWizard";
import { ButtonPrimary } from "../../styles/buttons";
import { useParams, useHistory } from "react-router-dom";
import { Alert, RadioLabel } from "../../styles/forms";
import SocialAuth from "./SocialAuth";
import SocialRegister from "./SocialRegister";
import { useSelector } from "react-redux";
import { updateProcess } from "../../providers/processProvider";
import { allRequiredFull } from "../../utils/validation";

const initialPayloadAuth = {
  name: "",
  esocial_access_code: "",
  esocial_password: "",
}

const initialPayloadData = {
  name: "",
  cpf_cnpj: "",
  rg: "",
  pis: "",
  titulo_de_eleitor: "",
}

const initialFiles = {
  proof_of_residence: {name: ""},
  proof_of_school_attendance: {name: ""},
}

const RegisterQuery = props => {

  const params = useParams();
  const history = useHistory();
  const [hasAuth, setHasAuth] = useState(null);
  const user = useSelector(state => state.auth.user);
  const [payloadAuth, setPayloadAuth] = useState(initialPayloadAuth);
  const [payloadData, setPayloadData] = useState(initialPayloadData);
  const [files, setFiles] = useState(initialFiles);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onChangeAuthHandler = e => {
    setPayloadAuth({...payloadAuth, [e.target.name]: e.target.value});
  }

  const onChangeDataHandler = e => {
    setPayloadData({...payloadData, [e.target.name]: e.target.value});
  }

  const onChangeSelected = e => {    
    setHasAuth(e.target.value);
  }

  const next = async e => {
    e.preventDefault();

    if(loading) return;
    setLoading(true);
    setError("");

    const step = Number(params.step);
    const url = `/app/adicionar/empregado/${step + 1}/${params.process}`;

    if(hasAuth === "true") {
      if(!allRequiredFull(payloadAuth)) {
        setError("Complete todos os campos obrigatórios para avançar");
        setLoading(false);
        return;
      }
      const result = await sendAuthInfo();
      if(result.error) {
        setError("Ocorreu um problema e não foi possível prosseguir. Tente novamente!");
        setLoading(false);
      }
      history.push(url);
      return;
    }

    //console.log(payloadData);

    if(!allRequiredFull(payloadData)) {
      setError("Complete todos os campos obrigatórios para avançar");
      setLoading(false);
      return;
    }

    if(!files.proof_of_residence.name || !files.proof_of_school_attendance.name) {
      setError("Adicione seus comprovantes para avançar");
      setLoading(false);
      return;
    }

    sendInfo(url);
  }

  const sendAuthInfo = async () => {
    const data = {
      step: 2,
      entity_data: {
        id: params.process,
        name: payloadAuth.name,
        employee_data: {
          esocial_access_code: payloadAuth.esocial_access_code,
          esocial_password: payloadAuth.esocial_password,
        }
      }
    }
    const result = await updateProcess(data, params.process, user.token);
    return result;
  }

  const onFileChange = e => {
    const file = e.target.files[0];
    if(!file) {
      return;
    }
    setFiles({...files, [e.target.name]: file});
  }

  const onDropResidence = e => {
    e.preventDefault();

    if (e.dataTransfer.items) {
      
        if (e.dataTransfer.items[0].kind === 'file') {
          const file = e.dataTransfer.items[0].getAsFile();
          console.log(file);
          setFiles({...files, proof_of_residence: file});
        }
      
    } else {
      console.log(e.dataTransfer.files[0]);
      setFiles({...files, proof_of_residence: e.dataTransfer.files[0]});
    }
  }

  const onDropSchoolAttendance = e => {
    e.preventDefault();

    if (e.dataTransfer.items) {
      
        if (e.dataTransfer.items[0].kind === 'file') {
          const file = e.dataTransfer.items[0].getAsFile();
          console.log(file);
          setFiles({...files, proof_of_school_attendance: file});
        }
      
    } else {
      console.log(e.dataTransfer.files[0]);
      setFiles({...files, proof_of_school_attendance: e.dataTransfer.files[0]});
    }
  }

  const sendInfo = async (redirectUrl) => {
    const data = {
      step: 2,
      entity_data: {
        id: params.process,
        name: payloadData.name,
        cpf_cnpj: payloadData.cpf_cnpj,
        employee_data: {
          rg: payloadData.rg,
          pis: payloadData.pis,
          titulo_de_eleitor: payloadData.titulo_de_eleitor,
        }
      }
    }
    const result = await updateProcess(data, params.process, user.token);

    if(result.error) {
      setError("Ocorreu um problema e não foi possível prosseguir. Tente novamente!");
      setLoading(false);
      return;
    }

    let formData = new FormData();
    formData.append("proof_of_residence", files.proof_of_residence);
    formData.append("proof_of_school_attendance", files.proof_of_school_attendance);

    const resultSendFiles = await updateProcess(formData, params.process, user.token);
    if(resultSendFiles.error) {
      setError("Ocorreu um problema e não foi possível prosseguir. Tente novamente!");
      setLoading(false);
      return;
    }

    history.push(redirectUrl);
  }

  return (
    <FormWizard>
      <div className="content">

        <div className="content-header">
          <h2>Possui cadastro no E-Social?</h2>
        </div>

        <div className="content-body">
          <RadioLabel>
            <input type="radio" 
              value={"true"}
              checked={hasAuth === "true"}
              onChange={onChangeSelected}
            />
            <span>Sim, já possuo cadastro</span>
          </RadioLabel>
          {hasAuth === "true" ? <SocialAuth onChangeHandler={onChangeAuthHandler} /> : null}
          <RadioLabel>
            <input type="radio" 
              value={"false"}
              checked={hasAuth === "false"}
              onChange={onChangeSelected}
            />
            <span>Não possuo cadastro</span>
          </RadioLabel>
          {
            hasAuth === "false" 
              ? <SocialRegister 
                  onChangeHandler={onChangeDataHandler} 
                  onDropResidence={onDropResidence}
                  onDropSchoolAttendance={onDropSchoolAttendance}
                  onFileChange={onFileChange}
                  files={files}
                /> 
              : null
          }
        </div>

      </div>

      <Alert show={error}>{error}</Alert>

      <ButtonPrimary app disabled={!hasAuth} className="block" onClick={next}>
        {loading ? "Carregando..." : "Avançar"}
      </ButtonPrimary>
    </FormWizard>
  )
}
export default RegisterQuery;
