import React, { useState } from "react";
import FormWizard, { PartnerList } from "../FormWizard/FormWizard";
import { ButtonPrimary, ButtonSubmit, LinkButton } from "../../styles/buttons";
import { useParams, useHistory } from "react-router-dom";
import PartnerModal from "./PartnerModal";
import PartnerEditModal from "./PartnerEditModal";
import { useSelector } from "react-redux";
import { updateProcess } from "../../providers/processProvider";
import { Alert } from "../../styles/forms";

const Partners = props => {

  const user = useSelector(state => state.auth.user);
  const params = useParams();
  const history = useHistory();
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [list, setList] = useState([]);
  const [error, setError] = useState("");

  const next = async e => {
    e.preventDefault();
    const step = Number(params.step);
    const nextStep = step + 1;
    const result = await updateProcess({step: nextStep}, params.process, user.token);
    if(result.error) {
      setError("Não foi possível continuar. Verifique sua conexão e tente novamente");
      return;
    }
    
    history.push(`/app/adicionar/abrir/${step + 1}/${params.process}`);
  }

  const openModal = e => {
    e.preventDefault();
    setAddModalOpen(!addModalOpen);
  }

  const editList = newItem => {
    const newList = list.map(item => {
      if(item.id !== newItem.id) {
        return item;
      }
      return newItem;
    })
    setList(newList);
    setEditingItem(null);
  }

  const selectItemToEdit = item => {
    setEditingItem(item);
  }

  return (
    <>
    <FormWizard>
      <div className="content">

        <div className="content-header">
          <h2>Sócios</h2>
          <p>Adicione seus sócios clicando no botão abaixo</p>
        </div>

        <div className="content-body">

          <ButtonSubmit onClick={openModal}>
            Adicionar Sócio
          </ButtonSubmit>

          <PartnerList>
            {list.map(item => (
              <li key={item.cpf}>
                <span>CPF - {item.cpf}</span>
                <LinkButton onClick={e => {
                    e.preventDefault();
                    selectItemToEdit(item);
                  }}>
                    Editar
                </LinkButton>
              </li>
            ))}
          </PartnerList>
          
        </div>
        
      </div>
      <Alert show={error}>{error}</Alert>
      <ButtonPrimary app className="block" onClick={next}>Avançar</ButtonPrimary>
    </FormWizard>

    <PartnerModal 
      show={addModalOpen}
      toggle={() => setAddModalOpen(!addModalOpen)}
      add={item => setList([...list, item])}
    />
    <PartnerEditModal 
      show={editingItem !== null}
      toggle={() => setEditingItem(null)}
      add={editList}
      partner={editingItem}
    />
    </>
  )
}
export default Partners;
