import React, { useState } from "react";
import FormWizard from "../FormWizard/FormWizard";
import { ButtonPrimary } from "../../styles/buttons";
import { useParams, useHistory } from "react-router-dom";
import { Label, Input, Alert, RadioLabel } from "../../styles/forms";
import { Grid } from "../../styles/grid";
import { useSelector } from "react-redux";
import { updateProcess } from "../../providers/processProvider";
import { allRequiredFull } from "../../utils/validation";

const initialPayload = {
  name: "",
  fantasy_name: "",
  email: "",
  phone: "",
  legal_nature: "",
  is_headquarters: true,
}

const CompanyInfo = props => {

  const { step, process } = useParams();
  const history = useHistory();
  const user = useSelector(state => state.auth.user);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(initialPayload);

  const onChangeHandler = e => {
    setForm({...form, [e.target.name]: e.target.value});
  }

  const onChangeSelected = (isHeadquarters) => {
    setForm({...form, is_headquarters: isHeadquarters});
  }

  const next = () => {
    const nextStep = Number(step) + 1;

    const nextType = props.migration ? "migrar" : "abrir";
    
    history.push(`/app/adicionar/${nextType}/${nextStep}/${process}`);
  }

  const send = async e => {
    e.preventDefault();
    if(loading) return;

    if(!allRequiredFull(form)) {
      setError("Complete todos os campos para avançar");
      return;
    }

    setLoading(true);
    setError("");

    //getProcess(2, user.token);

    const payload = {
      step: props.nextStep,
      entity_data: {
        id: process,
        name: form.name,
        company_data: {
          fantasy_name: form.fantasy_name,
          legal_nature: form.legal_nature,
          is_headquarters: form.is_headquarters,
          email: form.email,
          phone: form.phone,
        }
      }
    }

    const result = await updateProcess(payload, process, user.token);
    console.log(result);
    setLoading(false);
    
    if(result.error) {
      setError(result.errorMessage);
      return;
    }

    next()
  }

  return (
    <FormWizard>
      <div className="content">

        <div className="content-header">
          <h2>Informações da empresa</h2>
          <p>Nos informe abaixo as informações necessárias para abrir sua empresa</p>
        </div>

        <Grid className="content-body">

          <Label>
            <span>Nome completo</span>
            <Input name="name" onChange={onChangeHandler} placeholder="Nome completo" />
          </Label>
          <Label>
            <span>Nome fantasia</span>
            <Input name="fantasy_name" onChange={onChangeHandler} placeholder="Nome fantasia" />
          </Label>
          <Label>
            <span>E-mail</span>
            <Input name="email" onChange={onChangeHandler} placeholder="E-mail" />
          </Label>
          <Label>
            <span>Telefone</span>
            <Input name="phone" onChange={onChangeHandler} placeholder="Telefone" />
          </Label>
          <Label>
            <span>Natureza jurídica</span>
            <Input name="legal_nature" onChange={onChangeHandler} placeholder="Natureza jurídica" />
          </Label>
          <p>Matriz ou filial?</p>
          <RadioLabel className="col-3">
            <input type="radio" 
              value={true}
              checked={form.is_headquarters === true}
              onChange={() => onChangeSelected(true)}
            />
            <span>Matriz</span>
          </RadioLabel>
          <RadioLabel className="col-3">
            <input type="radio" 
              value={false}
              checked={form.is_headquarters === false}
              onChange={() => onChangeSelected(false)}
            />
            <span>Filial</span>
          </RadioLabel>
          
        </Grid>
        
      </div>

      <Alert show={error}>{error}</Alert>

      <ButtonPrimary app className="block" onClick={send}>
        Avançar
      </ButtonPrimary>
    </FormWizard>
  )
}
export default CompanyInfo;
