import React from "react";
import { ImageButton, IconButton } from "../../styles/buttons";
import { AppHeaderContainer } from "./headerStyles";
import { Link } from "react-router-dom";

import Logo from "../../assets/logo-symbol-white.png";
import iconCase from "../../assets/icon-case.png";
import iconProfile from "../../assets/icon-profile.png";
import iconPlus from "../../assets/icon-plus.png";

const AppHeader = props => {
  return (
    <AppHeaderContainer>

      <ImageButton className="logo" width="2.25rem" height="2.25rem" icon={Logo}>
        <Link to="/">
          Contabilizar
        </Link>
      </ImageButton>

      <nav>
        <Link to="/app/inicio/empresas">
          <IconButton icon={iconCase}>Início</IconButton>
        </Link>
        <Link to="/app/perfil/editar">
          <IconButton icon={iconProfile}>Perfil</IconButton>
        </Link>
        <Link to="/app/adicionar">
          <ImageButton icon={iconPlus}>Adicionar Conta</ImageButton>
        </Link>
      </nav>
      
    </AppHeaderContainer>
  )
}

export default AppHeader;
