import axios from "./config";

export const createProcess = async (payload, token) => {

  const config = {
    headers: { Authorization: `Token ${token}` }
  };

  try {
    const result = await axios.post("/process/", payload, config);
    //console.log(result);
    return result.data;
  } catch (error) {
    console.log(error.response);
    return {error: true, errorMessage: "Ocorreu um problema e não foi possível continuar seu cadastro de empresa"}
  }
};

export const updateProcess = async (payload, id, token) => {

  const config = {
    headers: { Authorization: `Token ${token}` }
  };

  try {
    const result = await axios.patch(`/process/${id}/`, payload, config);
    console.log(result);
    return result.data;
  } catch (error) {
    console.log(error.response);
    return {error: true, errorMessage: "Ocorreu um problema e não foi possível continuar seu cadastro de empresa"}
  }
};

export const getProcess = async (id, token) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(
      `/process/${id}`,
      config
    );
    // console.log(result.data);
    return result.data;
  } catch (error) {
    console.log(error.response);
    return { error: error.response };
  }
}


export const getCompanyList = async (userId, token) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(
      `/process/list/?user=${userId}&entity_type=company&group_by=process_status`,
      config
    );
    //console.log(result.data);
    return result.data;
  } catch (error) {
    console.log(error.response);
    return { error: error.response };
  }
}

export const getEmployeeList = async (userId, token) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(
      `/process/list/?user=${userId}&entity_type=employee&group_by=process_status`,
      config
    );
    //console.log(result.data);
    return result.data;
  } catch (error) {
    console.log(error.response);
    return { error: error.response };
  }
}

export const sendPartnerData = async (process, payload, token) => {

  var formData = new FormData();

  Object.entries(payload).forEach(entry => {
    const [key,value] = entry;    
    if(key === "address") {
      formData.append("proof_of_residence",value);
      return;
    }
    formData.append(key,value);
  })

  formData.append("process", process);

  //send formData to server...
  const config = {
    headers: { 
      Authorization: `Token ${token}`,
      "Content-Type": "multipart/form-data",
    }
  };

  try {
    const result = await axios.post("/partner/", formData, config);
    console.log(result);
    return result.data;
  } catch (error) {
    console.log(error.response);
    return {error: true, errorMessage: "Ocorreu um problema e não foi possível adicionar um sócio"}
  }

}

export const editPartner = async (id, payload, token) => {

  var formData = new FormData();

  Object.entries(payload).forEach(entry => {
    const [key,value] = entry;    
    if(key === "address") {
      formData.append("proof_of_residence",value);
      return;
    }
    formData.append(key,value);
  })

  //send formData to server...
  const config = {
    headers: { 
      Authorization: `Token ${token}`,
      "Content-Type": "multipart/form-data",
    }
  };

  try {
    const result = await axios.patch(`/partner/${id}/`, formData, config);
    console.log(result);
    return result.data;
  } catch (error) {
    console.log(error.response);
    return {error: true, errorMessage: "Ocorreu um problema e não foi possível editar o sócio"}
  }

}

export const addTransaction = async (payload, token) => {
  var formData = new FormData();

  Object.entries(payload).forEach(entry => {
    const [key,value] = entry;    
    formData.append(key,value);
  })

  //send formData to server...
  const config = {
    headers: { 
      Authorization: `Token ${token}`,
      "Content-Type": "multipart/form-data",
    }
  };

  try {
    const result = await axios.post(`/attachment/`, formData, config);
    console.log(result);
    return result.data;
  } catch (error) {
    console.log(error.response);
    return {error: true, errorMessage: "Ocorreu um problema e não foi possível anexar suas movimentações"}
  }
}

export const getReports = async (process, token, page=1) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(
      `/attachment/?process=${process}&is_from_accountant=true&page=${page}`,
      config
    );
    //console.log(result);
    return result.data;
  } catch (error) {
    console.log(error.response);
    return { error: error.response };
  }
}
