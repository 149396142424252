import styled from "styled-components";
import { colors } from "../../styles/constants";

export const ListContainer = styled.section`
  max-width: 560px;
  margin: auto;
  margin-top: 0.5rem;

  & > h3 {
    text-align: center;
  }

  &:not(:last-child) {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid ${colors.gray};
  }

`;
