import React from "react";
import { Route, NavLink, useParams } from "react-router-dom";
import { DotNavigator } from  "../../components/DotNavigator/DotNavigator";
import RegisterQuery from "../../components/DomesticEmployeeContent/RegisterQuery";
import EmployeePayment from "../../components/DomesticEmployeeContent/EmployeePayment";

const routes = [
  { step: '1', component: <RegisterQuery /> },
  { step: '2', component: <EmployeePayment /> },
];

const Content = props => {
  let { step } = useParams();

  const route = routes.find(route => route.step === step);

  if(route)
    return route.component;

  return null;

}

const DomesticEmployeeContainer = props => {

  const handleClick = e => {
    e.preventDefault();
  }

  return (
    <>
      <main>
        <h1>Cadastro de Empregado Doméstico</h1>

        <DotNavigator>
          {routes.map(route => (
            <NavLink 
              to={`/app/adicionar/empregado/${route.step}`} 
              key={`step-${route.step}`}
              onClick={handleClick}
            ></NavLink>
          ))}
        </DotNavigator>

        <Route path='/app/adicionar/empregado/:step/:process?' component={Content} />
      </main>
    </>
  )
}

export default DomesticEmployeeContainer;
