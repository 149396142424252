import styled from "styled-components";

export const SocialAuthContainer = styled.div`
  p {
    font-size: 0.9rem;
  }

  & > *, div input {
    margin-bottom: 1rem;
  }

`;
