import React from "react";

import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import LandingPageLayout from "./containers/LangindPageLayout";

import HomeScreen from "./screens/HomeScreen/HomeScreen";
import PlansScreen from "./screens/PlansScreen/PlansScreen";
import ContactProfessionalScreen from "./screens/ContactProfessionalScreen/ContactProfessionalScreen";
import SingInScreen from "./screens/SignInScreen/SignInScreen";
import { AuthPage } from "./styles/appStyles";
import SingUpScreen from "./screens/SignUpScreen/SignUpScreen";
import AppLayout from "./containers/AppLayout";
import ProfileLayout from "./containers/ProfileLayout";
import AddPlanScreen from "./screens/AddPlanScreen/AddPlanScreen";
import OpenCompanyContainer from "./containers/AddingPlanContainers/OpenCompanyContainer";
import DomesticEmployeeContainer from "./containers/AddingPlanContainers/DomesticEmployeeContainer";
import MigrateCompanyContainer from "./containers/AddingPlanContainers/MigrateCompanyContainer";
import ProcessesLayout from "./containers/ProcessesLayout";
import { useSelector } from "react-redux";

function AppRoute({ children, ...rest }) {
  const isLogged = useSelector((state) => state.auth.isLogged);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLogged ? (
          <AppLayout>
            {children}
          </AppLayout>
        ) : (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        )
      }
    />
  );
}

function AuthRoute({ children, ...rest }) {
  const isLogged = useSelector((state) => state.auth.isLogged);
  return (
    <Route
      {...rest}
      render={() =>
        isLogged ? (
          <Redirect
            to={{
              pathname: "/app/inicio/empresas",
            }}
          />
        ) : (
          <AuthPage>
            {children}
          </AuthPage>
        )
      }
    />
  );
}

function LandingPageRoute({ children, ...rest }) {
  const isLogged = useSelector((state) => state.auth.isLogged);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLogged ? (
          <Redirect
            to={{
              pathname: "/app/inicio/empresas",
            }}
          />
        ) : (
          <LandingPageLayout>
            {children}
          </LandingPageLayout>
        )
      }
    />
  );
}

export default function Routes() {
  return (
    <Router>
      
        <Switch>
          <AppRoute path="/app/inicio">
            <ProcessesLayout />
          </AppRoute>
          <AppRoute path="/app/adicionar" exact>
            <AddPlanScreen />
          </AppRoute>
          <AppRoute path="/app/adicionar/abrir">
            <OpenCompanyContainer />
          </AppRoute>
          <AppRoute path="/app/adicionar/migrar">
            <MigrateCompanyContainer />
          </AppRoute>
          <AppRoute path="/app/adicionar/empregado">
            <DomesticEmployeeContainer />
          </AppRoute>
          <AppRoute path="/app/perfil">
            <ProfileLayout />
          </AppRoute>
          <AuthRoute path="/login" exact>
            <SingInScreen />
          </AuthRoute>
          <AuthRoute path="/cadastro/:token?" exact>
            <SingUpScreen />
          </AuthRoute>
          <LandingPageRoute path="/planos" exact>
            <PlansScreen />
          </LandingPageRoute>
          <LandingPageRoute path="/contato-parceiro" exact>
            <ContactProfessionalScreen />
          </LandingPageRoute>
          <LandingPageRoute path="/">
            <HomeScreen />
          </LandingPageRoute>
        </Switch>
    </Router>
  );
}
