import styled from "styled-components";

export const Grid = styled.div`

  @media (min-width: 560px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 1rem;

    & > * {
      grid-column: 1 / span 12;
    }

    & > *.col-9 {
      grid-column: auto / span 9;
    }

    & > *.col-6 {
      grid-column: auto / span 6;
    }

    & > *.col-3 {
      grid-column: auto / span 3;
    }
  }

`;