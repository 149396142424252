import React from "react";
import { Route, NavLink, useParams } from "react-router-dom";
import { DotNavigator } from  "../../components/DotNavigator/DotNavigator";
import ValidateCompanyRegister from "../../components/MigrateCompanyContent/ValidateCompanyRegister";
import CompanyType from "../../components/MigrateCompanyContent/CompanyType";
import MigratePayment from "../../components/MigrateCompanyContent/MigratePayment";
import AddDigitalCertificate from "../../components/MigrateCompanyContent/AddDigitalCertificate";
import AccountantData from "../../components/MigrateCompanyContent/AccountantData";
import PrefectureRegister from "../../components/MigrateCompanyContent/PrefectureRegister";
import CompanyInfo from "../../components/OpenCompanyContent/CompanyInfo";

const routes = [
  { step: '1', component: <CompanyType /> },
  { step: '2', component: <ValidateCompanyRegister /> },
  { step: '3', component: <CompanyInfo migration nextStep={4} /> },
  { step: '4', component: <AddDigitalCertificate /> },
  { step: '5', component: <AccountantData /> },
  { step: '6', component: <PrefectureRegister /> },
  { step: '7', component: <MigratePayment /> },

];

const Content = props => {
  let { step } = useParams();

  const route = routes.find(route => route.step === step);

  if(route)
    return route.component;

  return null;

}

const MigrateCompanyContainer = props => {

  const handleClick = e => {
    e.preventDefault();
  }

  return (
    <>
      <main>
        <h1>Migração de Empresa</h1>

        <DotNavigator>
          {routes.map(route => (
            <NavLink
              onClick={handleClick}
              to={`/app/adicionar/migrar/${route.step}`}
              key={`step-${route.step}`}></NavLink>
          ))}
        </DotNavigator>

        <Route path='/app/adicionar/migrar/:step/:process?' component={Content} />
      </main>
    </>
  )
}

export default MigrateCompanyContainer;
