import styled from "styled-components";
import { colors } from "../../styles/constants";

export const LandingFooter = styled.footer`
  background-color: ${colors.primary};
  color: ${colors.light};
  padding: 1rem 1rem 0.5rem 1rem;

  p, li {
    color: ${colors.gray};
  }

  .contact-info {
    ul {
      margin: 1rem 0;
      li {
        margin: 0.5rem 0;
      }
    }
  }

  nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .footer-actions {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    & > * {
      margin: 0.5rem auto;
    }
  }

  .rights {
    text-align: center;
    font-size: 0.875rem;
  }

`;

export const FooterContent = styled.div`
  @media (min-width: 890px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    padding: 1rem;
    nav, .footer-actions {
      margin: 0;
    }
  }
`;
