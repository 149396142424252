import styled from "styled-components";
import { colors } from "../../styles/constants";

export const IndicationsContainer = styled.div`

  & > button {
    margin: auto;
  }

  .to-copy {
    display: none;
  }

  .pagination-button {
    margin: 0.5rem auto 0 auto;
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    min-width: auto;
  }

  section {
    margin: 2rem auto;
    max-width: 560px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    td {
      text-align: center;
      button {
        margin: auto;
        padding: 0;
      }
    }
    th, td {
      border-right: 1px solid black;
      padding: 0.5rem 0;
    }
    th:first-child, td:first-child {
      text-align: left;
    }
    td:last-child {
      border-right: none;
    }
  }

`;

export const AddContainer = styled.div`
  background-color: ${colors.light};
  padding: 1.5rem 1rem;
  border-radius: 4px;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1rem;
    text-align: center;
    margin-top:0;
  }

  button {
    margin: auto;
  }

  .link {
    max-width: 560px;
    overflow: auto;
    white-space: nowrap;
    padding-bottom: 0.5rem;
    flex: 1;
  }

  .link-container {
    display: flex;
    align-items: center;
  }

`;
