import React, { useState } from "react";
import { Form, RadioLabel } from "../../styles/forms";
import { ButtonPrimary } from "../../styles/buttons";
import { useHistory } from "react-router-dom";
import { createProcess } from "../../providers/processProvider";
import { useSelector } from "react-redux";

const options = {
  open: "abrir",
  migrate: "migrar",
  domestic: "empregado",
}

const AddPlanScreen = props => {

  const user = useSelector(state => state.auth.user);
  const [selected, setSelected] = useState(null);
  let history = useHistory();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const next = async e => {
    e.preventDefault();

    if(selected === options.domestic) {
      const result = await createEmployeeProcess();
      if(result.error) {
        setError(result.errorMessage);
        return;
      }
      history.push(`/app/adicionar/${selected}/1/${result.id}`);
      return;
    }

    history.push(`/app/adicionar/${selected}/1`);
  }

  const createEmployeeProcess = async () => {
    if(loading) return;
    setLoading(true);
    setError("");

    const result = await createProcess({
      user: user.id,
      plan: 3,
    }, user.token);
    setLoading(false);

    return result;
  }

  const onChangeSelected = e => {
    setSelected(e.target.value);
  }

  return (
    <main className="contain">
      <h1>Adicione um novo plano ao Contabilizar</h1>
      <Form>
        <RadioLabel>
          <input type="radio" 
            value={options.open}
            checked={selected === options.open}
            onChange={onChangeSelected}
          />
          <span>Abrir uma nova empresa</span>
        </RadioLabel>
        <RadioLabel>
          <input type="radio" 
            value={options.migrate}
            checked={selected === options.migrate}
            onChange={onChangeSelected}
          />
          <span>Migrar uma empresa existente</span>
        </RadioLabel>
        <RadioLabel>
          <input type="radio" 
            value={options.domestic}
            checked={selected === options.domestic}
            onChange={onChangeSelected}
          /> 
          <span>Registrar um empregado doméstico</span>
        </RadioLabel>

        <ButtonPrimary className="block" disabled={!selected} app onClick={next}>Avançar</ButtonPrimary>
      </Form>
    </main>
  )
}

export default AddPlanScreen;
