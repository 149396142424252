import React from "react";
import { Route, NavLink, useParams } from "react-router-dom";
import { DotNavigator } from  "../../components/DotNavigator/DotNavigator";
import CompanyType from "../../components/OpenCompanyContent/CompanyType";
//import Address from "../../components/OpenCompanyContent/Address";
import TaxAddress from "../../components/OpenCompanyContent/TaxAddress";
import OpenPayment from "../../components/OpenCompanyContent/OpenPayment";
import Partners from "../../components/OpenCompanyContent/Partners";
import Contract from "../../components/OpenCompanyContent/Contract";
import CompanyInfo from "../../components/OpenCompanyContent/CompanyInfo";

const routes = [
  { step: '1', component: <CompanyType /> },
  // { step: '2', component: <Address /> },
  { step: '2', component: <CompanyInfo nextStep={3} /> },
  { step: '3', component: <TaxAddress /> },
  { step: '4', component: <Partners /> },
  { step: '5', component: <Contract /> },
  { step: '6', component: <OpenPayment /> },
];

const Content = props => {
  let { step } = useParams();

  const route = routes.find(route => route.step === step);

  if(route)
    return route.component;

  return null;

}

const OpenCompanyContainer = props => {

  const handleClick = e => {
    e.preventDefault();
  }

  return (
    <>
      <main>
        <h1>Abertura de Empresa</h1>

        <DotNavigator>
          {routes.map(route => (
            <NavLink
              onClick={handleClick}
              to={`/app/adicionar/abrir/${route.step}`} 
              key={`step-${route.step}`}></NavLink>
          ))}
        </DotNavigator>

        <Route path='/app/adicionar/abrir/:step/:process?' component={Content} />
      </main>
    </>
  )
}

export default OpenCompanyContainer;
