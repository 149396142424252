// provides handlers with responses from the chatbot
// handlers can be called on MessageParser.parse or on Options "handler" prop
class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
  }

  respond() {
    const message = this.createChatBotMessage("Não existe essa opção. Digite um comando válido");
    this.updateChatbotState(message)
  }

  handlePlans = () => {
    const message = this.createChatBotMessage(
      `Confira nosso planos aqui:`, { widget: "link-planos" }
    );

    this.updateChatbotState(message);
  };

  handlePayments = () => {
    const message = this.createChatBotMessage(
      `Escolha um dos métodos disponíveis
      
      para saber mais:`, { widget: "paymentOptions" }
    );

    this.updateChatbotState(message);
  }

  updateChatbotState(message) {     
    this.setState(prevState => ({
      ...prevState, messages: [...prevState.messages, message]
    }))
  }

}

export default ActionProvider;
