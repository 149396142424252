import styled from "styled-components";
import { colors } from "./constants";

export const LandingPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  h1, h2 {
    text-transform: uppercase;
    text-align: center;
  }
  main {
    padding-top: calc(60px + 1rem);
    padding-bottom: calc((60px + 1rem) / 2);
    h1, h2, p {
      color: ${colors.dark};
    }
    button {
      display: block;
      width: 100%;
      /* margin: 0; */
    }
  }
  .marked {
    background-image: ${`linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 60%, ${colors.secondary} 60%, ${colors.secondary} 100%);`};
  }
`;
