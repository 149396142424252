import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getEmployeeList } from "../../providers/processProvider";
import { Alert } from "../../styles/forms";
import EmployeeList from "./EmployeeList";
import EmployeeProcessList from "./EmployeeProcessList";

const EmployeeListScreen = props => {

  const user = useSelector(state => state.auth.user);
  const [employees, setEmployees] = useState([]);
  const [processes, setProcesses] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {

      if(loading) return;
      setLoading(true);
      setError("");

      const result = await getEmployeeList(user.id, user.token);
      console.log(result);
      setLoading(false);

      if(result.error) {
        setError("Ocorreu um erro inesperado e não foi possível buscar os empregados cadastrados");
        return;
      }

      setEmployees(result.completed);
      setProcesses(result.incompleted);

    }

    getData();
    
  }, [user.id, user.token])

  return (
    <>
      <Alert show={error}>{error}</Alert>

      {employees.length === 0 && processes.length === 0 && !loading && !error ? (
        <Alert show={true}>Não há empregados cadastrados ainda</Alert>
      ) : null}

      {processes?.length > 0 ? <EmployeeProcessList list={processes} /> : null}
      {employees?.length > 0 ? <EmployeeList list={employees} /> : null}

    </>
  )
}

export default EmployeeListScreen;
