import axios from "./config";
import { loginSuccess, editUserSuccess } from "../store/ducks/auth";

export const register = payload => async dispatch => {

  try {
    const result = await axios.post("/user/", payload);
    console.log(result);
    dispatch(loginSuccess(result.data));
    return { error: false, data: result.data };
  } catch (error) {
    console.log(error.response.data);
    return { error:true, message:error.response.data }
  }
}

export const login = payload => async dispatch => {

  try {
    const result = await axios.post("/authentication/", payload);
    console.log(result);
    dispatch(loginSuccess(result.data));
    return { error: false, data: result.data };
  } catch (error) {
    console.log(error.response.data);
    return { error:true, message:error.response.data }
  }
}

export const editUser = (id, payload, token) => async dispatch => {

  const config = {
    headers: { Authorization: `Token ${token}` }
  };

  try {
    const result = await axios.patch(`/user/${id}/`, payload, config);
    console.log(result);
    dispatch(editUserSuccess(result.data));
    return { error: false, data: result.data };
  } catch (error) {
    console.log(error.response.data);
    return { error:true, message:error.response.data }
  }
}
