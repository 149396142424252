import axios from "axios";

let baseUrl = "http://mailingbox-api.devari.com.br/api/v1";

const instance = axios.create({
  baseURL: baseUrl
});

/* 
payload:
{
  "sender": "user@example.com",
  "to": "support@appexample.com",
  "appId": "9999xx-9xx-xxxxx-999-xxxx999999",
  "text": "Hello support! I would like to report an error"
} 
*/
export const sendEmail = async payload => {
  const data = {
    "sender": payload.sender,
    "to": "sher@fortalshop.com",
    "appId": "12b9133a-9a3d-4f47-869e-ef6a6eca8ad3",
    "text": `
      ${payload.name}
      ${payload.phone}

      ${payload.message}
    `
  }
  console.log(data);
  try {
    const result = await instance.post("/send-mail/", data);
    console.log(result);
    return result;
  } catch (error) {
    console.log(error.response);
    return {error: true, errorMessage: "Ocorreu um problema e não foi possível enviar seus dados"}
  }
};
