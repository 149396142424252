import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../styles/constants";
import { LinkButton, ButtonPrimary } from "../../styles/buttons";
import Modal from "../Modal/Modal";
import { Label, Input, LabelFile, LabelDraggable, Alert } from "../../styles/forms";
import { editPartner, sendPartnerData } from "../../providers/processProvider";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const Container = styled.div`
  background-color: ${colors.light};
  padding: 1.5rem;
  border-radius: 4px;
  width: 316px;

  h2 {
    text-align: center;
    font-size: 1.175rem;
  }

  form {
    margin: 2rem 0;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    button {
      margin: 0;
    }
  }
`;

const initialData = {
  rg: "",
  cpf: "",
  address: {name: ""},
}

const PartnerEditModal = props => {

  const user = useSelector(state => state.auth.user);
  const [partnerData, setPartnerData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onFileChange = e => {
    setPartnerData({...partnerData, [e.target.name]: e.target.files[0]});
  }

  const onDropFile = e => {
    e.preventDefault();

    const key = e.target.lastChild.name;

    if (e.dataTransfer.items) {
      
        if (e.dataTransfer.items[0].kind === 'file') {
          const file = e.dataTransfer.items[0].getAsFile();
          console.log(file);
          setPartnerData({...partnerData, [key]: file});
        }
      
    } else {
        console.log(e.dataTransfer.files[0]);
        setPartnerData({...partnerData, [key]: e.dataTransfer.files[0]});
    }
  }

  const send = async () => {
    if(loading) return;

    setLoading(true);
    setError("");

    const result = await editPartner(props.partner.id, partnerData, user.token);
    console.log(result);
    setLoading(false);
    
    if(result.error) {
      setError(result.errorMessage);
      return;
    }

    props.add(result);
    setPartnerData(initialData);
    props.toggle();
  }

  return (
    <Modal toggle={props.toggle} isOpen={props.show}>
      <Container>
        <h2>Adicionar Sócio</h2>
        <p>Anexe abaixo os documentos do sócio</p>
        <form>
          <Label>
            <span>RG</span>
            <Input value={partnerData.rg} placeholder="RG" onChange={e => setPartnerData({...partnerData, rg: e.target.value})} />
          </Label>
          <Label>
            <span>CPF</span>
            <Input value={partnerData.cpf} placeholder="CPF" onChange={e => setPartnerData({...partnerData, cpf: e.target.value})} />
          </Label>
          <LabelDraggable
            onDragEnter={() => console.log("drag enter")}
            onDragLeave={() => console.log("drag leave")}
            onDragOver={e => e.preventDefault()}
            onDrop={onDropFile}
          >
            Comprovante de endereço {partnerData.address.name}
            <input name="address" type="file" onChange={onFileChange} />
          </LabelDraggable>
        </form>
        <Alert show={error}>{error}</Alert>
        <div className="button-container">
          <ButtonPrimary app onClick={send}>
            {loading ? "Carregando..." : "Editar"}
          </ButtonPrimary>
          <LinkButton onClick={props.toggle}>Cancelar</LinkButton>
        </div>
      </Container>
    </Modal>
  )
}

export default PartnerEditModal;
