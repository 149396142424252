import React from "react";
import Options from "./Options";

const PaymentOptions = (props) => {
  const options = [
    { text: "Boleto", handler: () => {}, id: 1 },
    { text: "Cartão de crédito", handler: () => {}, id: 2 },
  ];

  return <Options {...props} options={options} />;
};

export default PaymentOptions;
