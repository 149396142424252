import React, { useState } from "react";
import FormWizard from "../FormWizard/FormWizard";
import { ButtonPrimary, ButtonSubmit } from "../../styles/buttons";
// import { useParams, useHistory } from "react-router-dom";
// import { RadioLabel, Label, Input } from "../../styles/forms";
import { Grid } from "../../styles/grid";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { generatePayment } from "../../providers/paymentProvider";
import { updateProcess } from "../../providers/processProvider";
import { Alert } from "../../styles/forms";

const options = {
  credit: "credit",
  bill: "bill",
}

//pass a config prop to get payment value
const Payment = props => {

  const user = useSelector(state => state.auth.user);
  const params = useParams();
  const [selected, setSelected] = useState(options.bill);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // const onChangeSelected = e => {
  //   setSelected(e.target.value);
  // }

  const handleClick = e => {
    e.preventDefault();
    //implement payment when credit card...
    //pass a config prop to get payment value
    
    //after payment success...
    props.next();
  }

  const requestPayment = async e => {
    e.preventDefault();
    if(loading) return;
    setLoading(true);
    setError("");

    const boleto = await generatePayment(params.process, user.token);
    console.log(boleto);

    if(boleto.error) {
      //show error message
      setLoading(false);
      setError(boleto.errorMessage);
      return;
    }

    const result = await updateProcess({completed: true}, params.process, user.token);
    console.log(result);
    if(result.error) {
      //show error message
      console.warn(result.error);
      setError(result.error);
      setLoading(false);
      return;
    }

    doDownloadPdf(boleto.boleto);
    setLoading(false);

  }

  const doDownloadPdf = url => {
    console.log(url);
    var a = document.createElement('A');
    var filePath = url;
    a.href = filePath;
    a.target = "_blank";
    a.rel = "noopener noreferrer"
    a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <FormWizard>
      <div className="content">

        <div className="content-header">
          <h2>Pagamento</h2>
          <p>Faça o download do boleto clicando no botão abaixo</p>
        </div>

        <Grid className="content-body">
          {/* <RadioLabel>
            <input type="radio" 
              value={options.credit}
              checked={selected === options.credit}
              onChange={onChangeSelected}
            />
            <span>Crédito</span>
          </RadioLabel>
          {selected === options.credit ? (
            <>
              <Label>
                <span>Número do cartão</span>
                <Input placeholder="Número do cartão" />
              </Label>
              <Label className="col-6">
                <span>Validade</span>
                <Input placeholder="Validade (mm/aaaa)" />
              </Label>
              <Label className="col-6">
                <span>CVV</span>
                <Input placeholder="CVV" />
              </Label>
              <Label>
                <span>Nome do titular</span>
                <Input placeholder="Nome do titular" />
              </Label>
              <Label>
                <span>CPF</span>
                <Input placeholder="CPF" />
              </Label>
            </>
          ) : null} */}
          {/* <RadioLabel>
            <input type="radio" 
              value={options.bill}
              checked={selected === options.bill}
              onChange={onChangeSelected}
            />
            <span>Boleto</span>
          </RadioLabel> */}
          {selected === options.bill ? (
            <>
              <p className="center"><strong>{props.price ? `RS ${props.price}` : ""}</strong></p>
              <ButtonSubmit disabled={loading} onClick={requestPayment}>
                {loading ? "Aguarde..." : "Gerar boleto"}
              </ButtonSubmit>

              <Alert show={error}>{error}</Alert>

            </>
          ) : null}
        </Grid>

      </div>

      <ButtonPrimary app className="block" onClick={handleClick}>Concluir</ButtonPrimary>
    </FormWizard>
  )
}
export default Payment;
