import React, { useEffect, useState } from "react";
import FormWizard from "../FormWizard/FormWizard";
import { ButtonPrimary } from "../../styles/buttons";
import { useParams, useHistory } from "react-router-dom";
import { RadioLabel, Alert } from "../../styles/forms";
import { getPlans } from "../../providers/planProvider";
import { createProcess } from "../../providers/processProvider";
import { useSelector } from "react-redux";

const initialPlans = [
  {
    name: "MEI",
    id: 1
  },
  {
    name: "Simples Nacional",
    id: 2
  }
]

const CompanyType = props => {

  const params = useParams();
  const history = useHistory();
  const user = useSelector(state => state.auth.user);
  const [plans, setPlans] = useState(initialPlans);
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   const getData = async () => {
  //     const data = await getPlans();
  //     if(data.error) {
  //       //setError(data.error);
  //       return;
  //     }
  //     console.log(data);
  //     setPlans(data);

  //   }
  //   getData();
  // }, [])

  const onChangeSelected = e => {
    console.log(e.target.value);
    
    setSelected(e.target.value);
  }

  const next = id => {
    const step = Number(params.step);
    
    history.push(`/app/adicionar/abrir/${step + 1}/${id}`);
  }

  const send = async e => {
    e.preventDefault();
    if(loading) return;
    setLoading(true);
    setError("");

    const payload = {
      user: user.id,
      plan: selected,
      is_opening: true,
    }
    const result = await createProcess(payload, user.token);
    console.log(result);
    setLoading(false);
    
    if(result.error) {
      setError(result.errorMessage);
      return;
    }

    next(result.id)
  }

  return (
    <FormWizard>
      <div className="content">

        <div className="content-header">
          <h2>Tipo de empresa</h2>
          <p>Selecione o tipo da sua empresa</p>
        </div>

        <div className="content-body">
          {plans.map(plan => (
            <RadioLabel key={plan.id}>
              <input type="radio" 
                value={plan.id}
                checked={selected === `${plan.id}`}
                onChange={onChangeSelected}
              />
              <span>{plan.name}</span>
            </RadioLabel>
          ))}
        </div>

      </div>

      <Alert show={error}>{error}</Alert>

      <ButtonPrimary app className="block" 
        disabled={selected === null}
        onClick={send}
      >
        {loading ? "Carregando..." : "Avançar"}
      </ButtonPrimary>
    </FormWizard>
  )
}
export default CompanyType;
