import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generateMonthlyPayment, getPaymentHistory } from "../../providers/paymentProvider";
import { ButtonPrimary, ButtonSubmit, LinkButton } from "../../styles/buttons";
import { convertDate } from "../../utils/dates";
import Modal from "../Modal/Modal";
import { PaymentItem, PaymentsContainer } from "./paymentHistoryStyles";

const PaymentHistory = props => {

  const user = useSelector(state => state.auth.user);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(false);
  
  useEffect(() => {
    if(!props.show) return;
    if(limit) return;
    const getData = async () => {
      const result = await getPaymentHistory(props.processId, user.token, page);
      if(result.error) {
        return;
      }
      console.log(result.results);
      setList(state => [...state, ...result.results]);
      if(!result.next) {
        setLimit(true);
      }
    }
    getData();
  }, [props.processId, user.token, props.show, page, limit])

  const nextPage = () => {
    if(limit) return;
    setPage(page + 1);
  }

  const generateNewBoleto = async (itemId) => {
    console.log("payment_id", itemId);
    console.log("type", "MENSALIDADE");
    console.log("process", props.processId);

    const result = await generateMonthlyPayment(props.processId, itemId, user.token);

    console.log(result);

    if(result.error) {
      //show error message
      return;
    }

    //open document in new tab
    doDownloadPdf(result.boleto);
  }

  const doDownloadPdf = url => {
    console.log(url);
    var a = document.createElement('A');
    var filePath = url;
    a.href = filePath;
    a.target = "_blank";
    a.rel = "noopener noreferrer"
    a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const renderPaymentButton = (item) => {
    if(item.can_generate_boleto) {
      return (
        <ButtonPrimary onClick={() => generateNewBoleto(item.id)} app>Gerar boleto</ButtonPrimary>
      )
    }

    if(item.status === "EM ABERTO") {
      return (
        <a target="_blank" rel="noopener noreferrer" className="document-link"
          href={item.boleto}
        >
          <LinkButton>Ver boleto</LinkButton>
        </a>
      )
    }

    if(item.status === "PAGO") {
      return <p className="paid-badge">Pago</p>
    }

    return null;

  }

  return (
    <Modal toggle={props.toggle} isOpen={props.show}>
      <PaymentsContainer>
        <h4>Pagamentos</h4>

        {limit && list.length === 0 ? (
          <p className="center">Não há pagamentos disponíveis</p>
        ) : null}

        <div className="report-item-list">
        {list.map(item => (
          <PaymentItem key={`report-${item.id}`}>
            <div className="row">
              <p>{item.description}</p>
            </div>
            <div className="row">
              <p>Valor: <strong>RS {item.value}</strong></p>
              {renderPaymentButton(item)}
            </div>
          </PaymentItem>

        ))}
        </div>

        {!limit ? (
          <ButtonSubmit onClick={nextPage}>
            Ver mais
          </ButtonSubmit>
        ) : null}

      </PaymentsContainer>
    </Modal>
  )
}

export default PaymentHistory;
